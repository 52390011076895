import * as actions from "../../actions/action-type";
import { IsLoggedInAction, IsLoggedInState } from "../../models/islogged.model";

const initialState: IsLoggedInState = {
    username: '',
    userId: -1,
    isLoggedIn: false
}

export const IsLoggedInReducer = (
    state: IsLoggedInState = initialState,
    action: IsLoggedInAction
): any => {
    switch (action.type) {
        case actions.SET_AUTHENTIFICATION:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
                username: action.username,
                userId: action.userId
            };

        default:
            return state;
    }
}



