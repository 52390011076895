import * as React from "react"
import { PreviewComponent } from "../common/preview.component";
import { RowColumnComponent } from "../ui/container/row-colums.component";
// import { Dispatch } from "redux"
// import { useDispatch } from "react-redux"


export const HomePage: React.FC<{}> = () => {
    //   const dispatch: Dispatch<any> = useDispatch()

    //   const deleteArticle = React.useCallback(
    //     (article: IArticle) => dispatch(removeArticle(article)),
    //     [dispatch, removeArticle]
    //   )

    const [todo, setTodo] = React.useState('');
    const [done, setDone] = React.useState('');

    const getData = () => {
        fetch('TODO.md',
            {
                'headers': {
                    'Content-Type':'text/markdown',
                    'Accept':'text/markdown'
                }
            }
        ).then((response:Response) => {
            return response.text();
        }).then((text:string) => {
            setTodo(text);
        })

        fetch('DONE.md',
            {
                'headers': {
                    'Content-Type':'text/markdown',
                    'Accept':'text/markdown'
                }
            }
        ).then((response:Response) => {
            return response.text();
        }).then((text:string) => {
            setDone(text);
        })
    }


    React.useEffect(()=>{
        getData()
      },[])

    return (
        <>
            <div className="home container">
                <h2> CRayꝎ </h2>
                <img src="./crayoo.svg" className="image" alt="explanation" />
                <h2> Roadmap </h2>
                <RowColumnComponent>
                    <div>
                        <h2> Done </h2>
                        <PreviewComponent text={ done } ></PreviewComponent>
                    </div>
                    <div>
                        <h2> Todo </h2>
                        <PreviewComponent text={ todo } ></PreviewComponent>
                    </div>
                </RowColumnComponent>
            </div>
        </>
    )
}