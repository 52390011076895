import { Actions } from "../../actions/action-type";
import { ErrorAction } from "../../models/error.model";
import { CommonState } from '../../models/common.model';

const initialState: CommonState = {
    error: '',
    fromUrl: ''
}

export const CommonReducer = (
    state: CommonState = initialState,
    action: ErrorAction
): CommonState => {
    switch (action.type) {
        case Actions.SET_ERROR:
            return {
                ...state,
                error: action.message
            };
        case Actions.SET_FROM:
            return {
                ...state,
                fromUrl: action.message
            }
        default:
            return state;
    }
}



