import { Report } from "../models/report.model";

/**
 * Return true if user can delete
 * @param report 
 */
export const canDelete = (report: Report) => {
    // True if access mode and accessMode === 'OWNER'
    return isOwner(report);
}

/**
 * Return true if user can edit (Owner / Writer)
 * @param report
 */
export const canEdit = (report: Report) => {
    // True if access mode, accessMode Write or Owner
    return report && report.accessMode && (
        report.accessMode === 'OWNER' || report.accessMode === 'WRITER'
    );
}

/**
 * Return true if user has accessMode
 * @param report
 */
export const canRead = (report: Report): boolean => {
    // True if access mode ..
    return report.accessMode ? true : false;
}

/**
 * Return true to edit var
 * @param report 
 */
export const canManageVar = (report: Report) => {
    return isOwner(report);
}

/**
 * Return true if user can take snapshot
 * @param report 
 * @returns 
 */
export const canTakeSnapshot = (report: Report) => {
    return isOwner(report);
}

/**
 * Return true if user is owner
 * @param report The report
 * @returns 
 */
export const isOwner = (report: Report) => {
     // True if access mode and accessMode === 'OWNER'
     return report.accessMode && report.accessMode === 'OWNER';
}