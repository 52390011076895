import * as actions from "../action-type";
import { Dispatch } from "react";
import { Report, ReportAction, ReportChild } from "../../models/report.model";
import { patchOneContent, PatchOneContentAction } from "../report-content/report-content-patch-one.action";


/**
 * Clone the report, send to redux and call patch one
 * @param report The original report
 * @param child The child to be saved
 * @param action The action wich gone be send
 * @param dispatch Dispatch
 */
export const updateAndSaveChild = (
    report: Report, 
    child: ReportChild, 
    action: PatchOneContentAction,
    dispatch: Dispatch<any>,
    ) => {

    // Clone report
    report = { ...report }

    // Send data to redux
    dispatch({
        type: actions.PUT_UPDATE_CONTENT,
        reports: [report]
    } as ReportAction);

    // Send data to the server
    dispatch(patchOneContent(
        report,
        child,
        action
    ));
}