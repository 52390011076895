import * as React from "react";
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps,  withRouter } from "react-router-dom";
import { RootState } from '../../models/root-state.model';
import { completeReport, getReport } from '../../actions/report.action';

interface TParams {
    id: string
}


export const requireReport = <P extends object>(Component: React.ComponentType<P>) => {

    const RequireReport: React.FunctionComponent<P & RouteComponentProps<TParams>> = (props) => {

        const dispatch: Dispatch<any> = useDispatch();
    
        const { history, match, ...other} = props;

        const id  = match?.params.id;
        const report= useSelector((store:RootState) => {
            return store.reports.reports.find(r => r.id.toString() === id );
        });
        const [username, userId] = useSelector((store:RootState) => {
            return [store.authentification.username, store.authentification.userId];
        })

        // Complete
        if(report) {
            dispatch(completeReport(report));
        } else {
            dispatch(getReport(id));
        }


        return <>
            {
                 <Component 
                    {...other as P}
                    history={history}
                    match={match} 
                    id={id}
                    report={report}
                    username={username}
                    userId={userId}
                    /> }
        </>
    }

  return withRouter(RequireReport);

}