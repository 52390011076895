import * as React from "react";
import { connect } from 'react-redux';
import { Constant } from '../../constants';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FieldInputComponent } from '../ui/field/field-input.component';



export interface IFormSnapshotData {
    label:string;
    onlyDiff:boolean;
}

interface IErrorsForm {
    label?:string;
}
  
const formValidation = (values:IFormSnapshotData) => {

    const errors:IErrorsForm = {};

    if(!values.label) {
    errors.label = "Please provide label";
    }

    return errors;
};

interface IFormProps {
    initialValues?: { label: string, onlyDiff: boolean } 
}

interface IFormDispatchProps {
    onSubmit: (form: IFormSnapshotData) => void
}
  
const SnapshotFormComponentBase: React.FunctionComponent<IFormProps & IFormDispatchProps & InjectedFormProps<IFormSnapshotData, IFormProps & IFormDispatchProps>> = (props) => {

    const { handleSubmit, onSubmit } = props;

    const manageSubmit = onSubmit;

    return <form onSubmit={handleSubmit(manageSubmit)} >
        <Field
            name="label"
            component= { FieldInputComponent }
            type="text"
            label="Label"
        />
        <div>
            <label><Field name="onlyDiff" id="onlyDiff" component="input" type="checkbox" /> Only differences </label>
        </div>
    </form>
};
  
const SnapshotComponentReduxForm = reduxForm<IFormSnapshotData, IFormProps & IFormDispatchProps>({
form: Constant.FORM_ADD_SNAPSHOT,
validate: formValidation,
})(SnapshotFormComponentBase);
  
export const SnapshotComponentForm = connect()(SnapshotComponentReduxForm);