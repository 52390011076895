import * as React from "react";

export interface DropDownItem {
    code: string|number,
    label: string,
    color?: string
}

interface Props extends React.HTMLAttributes<HTMLDivElement > {
    id:string
    items: DropDownItem[],
    className?:string,
    color?: string,
    onClickItem: (item: DropDownItem) => void
}

export const DropDownButtonComponent: React.FunctionComponent<Props> = (props) => {

  const { id, items, className='', color='light', onClickItem, ...other } = props;

  const handleClick = (e: React.SyntheticEvent, item: DropDownItem) => {
        e.preventDefault();
        onClickItem(item);
  }

  return (
      <>
          <div className="dropdown dropleft " { ...other } >
            <button
                className={`btn btn-${color} 
                dropdown-toggle ${className}`} 
                type="button" 
                id={ id } 
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                >
                { props.children } 
            </button>
            <div className="dropdown-menu" aria-labelledby="{id}">
                {
                    items.map(
                        item =>  {
                            const c = item.color ? `bg-${item.color}` : '';
                            return <button key={item.code} className={ `dropdown-item ${c} `} onClick={(e) => handleClick(e, item) } > { item.label } </button>
                        }
                    )
                }
            </div>
        </div>
      </>
  )
}

