import * as React from "react";
import * as ReactDOM from "react-dom";


// assuming in your html file has a div with id 'modal-root';

interface Props {
    title: string,
    onClose: () => void,
    onSave: () => void,
    children: React.ReactNode,
    closeTitle?: string,
    saveTitle?: string
}

export const ModalComponent:React.FunctionComponent<Props> = ( props :Props) => {
    // Get the HTML element
    const modalRoot = document.getElementById("modal-root") as HTMLElement;
    const [el] = React.useState<HTMLElement>(document.createElement("div"));

    // Get the props
    const {
        title,
        onClose,
        onSave,
        children,
        closeTitle = 'Cancel',
        saveTitle = 'Save'
    } = props;

    // Manage mount and unmount
    React.useEffect(() => { 
        modalRoot.appendChild(el);
        return () => { modalRoot.removeChild(el);  }
    }, [modalRoot, el])

    return ReactDOM.createPortal(
        <>
            <div className="modal fade show " tabIndex={-1} role="dialog" style={ { display: 'block' }} >
                <div className="modal-dialog modal-xl modal-dialog-scrollable" style={ { maxWidth:'75%' } }>
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> { title }  </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={ onClose } >
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    { children }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={ onClose }  > { closeTitle } </button>
                        <button type="button" className="btn btn-primary" onClick={ onSave } data-test-id="btnModalValid" > { saveTitle } </button>
                    </div>
                    </div>
                </div>
            </div>
        </>
        , el
    );


}


