// import * as React from "react";
import * as actions from "../../actions/action-type";
import { ReportAction, ReportState } from "../../models/report.model";

const initialState: ReportState = {
    reports: []
}

export const ReportReducer = (
    state: ReportState = initialState,
    action: ReportAction
): ReportState => {
    switch (action.type) {
        case actions.GET_CONTENT_LIST:
            return {
                ...state,
                reports: [...action.reports]
            }
        case actions.POST_NEW_CONTENT:
            return {
                ...state,
                reports: [...state.reports, ...action.reports]
            }
        case actions.PUT_UPDATE_CONTENT:
            // Delete if exist
            action.reports.forEach(report => {
                state.reports = state.reports.filter(r => r.id !== report.id);
            });
            // Add
            return {
                ...state,
                reports: [...state.reports, ...action.reports]
            }
        case actions.DELETE_CONTENT:
            const tempDelete = { ...state };
            action.reports.forEach(report => {
                tempDelete.reports = tempDelete.reports.filter(r => r.id !== report.id);
            });
            return tempDelete;
        default:
            return state;
    }
}