import * as React from "react";
import { PrimaryButtonComponent } from "../button";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onValidClick: (e:React.SyntheticEvent) => void,
    inputRef: React.MutableRefObject<HTMLInputElement | null>,
    defaultValue?:string,
    showButton?: boolean
}

export const InputTextWithButtonComponent: React.FunctionComponent<Props> = (props) => {

    const { onValidClick, inputRef, defaultValue, showButton = true, ...others } = props;


    const handleOnKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter') {
            onValidClick(e);
        }

    }, [onValidClick]);

    return <>
         <div className="input-group" { ...others }>
             
            <input 
                type="text" 
                className="form-control" 
                defaultValue={defaultValue} 
                ref={inputRef} 
                onKeyDown={handleOnKeyDown}
            />
            { 
                showButton && <div className="input-group-append">
                    <PrimaryButtonComponent id="button-addon2" onClick={ onValidClick } > { props.children } </PrimaryButtonComponent>
                </div>
            }
        </div>
    </>;
}