import * as React from "react"

interface Props {
    type?: string,
    message: string
}

export const AlertComponent: React.FunctionComponent<Props> = ({ type='info', message, children }) => {
    
    return <>
        { 
            message
                && 
                    <div className="container">
                        <div className={"alert alert-" + type } > 
                            <div> { message } { children } </div>
                        </div>
                    </div>
        }
    </>;
}