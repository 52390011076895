import { Dispatch } from "react";
import { AnyAction } from "redux";
import { Report, ReportChild } from "../../models/report.model";
import { request } from "../../services/http.service";
import { setError } from "../common.action";

export type PatchOneContentAction =  "update" | "delete" | "insert";

/**
 * Send a patch request to the server to update onl
 * @param report 
 */
 export const patchOneContent = (
     report: Report,
     child: ReportChild,
     action: PatchOneContentAction
) => {

    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            await request({
                method: 'PATCH',
                url: `/v1/content/${report.id}/one/${child.key}`,
                data: {
                    'action': action,
                    'children': child
                }
            });

        } catch (error) {
            dispatch(setError(error));
        }
    }
}