import * as React from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement > {
   
}

export const SubmitButtonComponent: React.FunctionComponent<Props> = props => {

  const { ...other } = props;

  return (
      <>
           <button type="submit" className="btn btn-primary btn-raised" {...other} >
            { props.children }
          </button>
      </>
  )
}

