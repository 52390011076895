
import { Dispatch } from 'redux';
import { Report, ReportChild, ReportChildTable } from '../models/report.model';
import { setError } from './common.action';
import { replaceBetween } from "../services/string.service";
import { Constant } from "../constants";
import { updateOneChild } from "./report-content-child/report-content-update-one-child.action";

const defaultTableContent = Constant.DEFAULT_TABLE_CONTENT;

/**
 * Add a new table to the child
 * /!\ : Do not save !!! Only edit save
 * @param report Report
 * @param child ReportChild
 */
export const addTable = (report: Report, child: ReportChild, title: string) => {

    return async (dispatch: Dispatch<any>) => {
        try {
            // Create the new var 
            const table: ReportChildTable = {
                key: Date.now().toString(),
                label: title,
                content: defaultTableContent
            };

            // Check
            if(! child.tables) { child.tables = []; }

            // Add
            child.tables = [
                ...child.tables,
                table
            ];

        } catch (error) {
            dispatch(setError(error));
        }
    }

}


/**
 * Edit a table on the list and save
 * @param report Report
 * @param child ReportChild
 * @param table Table to edit
 */
export const editTable = (report: Report, child: ReportChild, table: ReportChildTable) => {

    return async (dispatch: Dispatch<any>) => {
        try {

            // Create the new var 
            const editTable: ReportChildTable = {
                ...table
            };

            // Check
            if(! child.tables) { child.tables = []; }

            // Edit
            child.tables = child.tables!.map(v => v.key === editTable.key ? editTable : v);

            // Update the content
            // -- Search
            const search = `<internal-table-start id='${table.key}' />\n\n`;
            const indexStart = child.text.indexOf(search) + search.length;
            const indexEnd = child.text.indexOf('\n\n<internal-table-end/>', indexStart);
            if(indexStart !== search.length  && indexEnd !== -1) {
                child.text = replaceBetween(
                    child.text,
                    indexStart, indexEnd,
                    table.content
                );
            }

            // Update and save
            dispatch(updateOneChild(report, child));

        } catch (error) {
            dispatch(setError(error));
        }
    }
}

/**
 * Generate a string to include in the editor for the table
 * @param table 
 */
export const generateContentForEditor = (table: ReportChildTable) => {
    return `<internal-table-start id='${table.key}' />\n\n${table.content}\n\n<internal-table-end/>\n\n`;
}

/**
 * Delete a table from the child
 * @param report 
 * @param code 
 */
export const deleteTable = (report: Report, child: ReportChild, tableKey: string) => {

    return async (dispatch: Dispatch<any>) => {
        try {

            // Check
            if(! child.tables) { child.tables = []; }

            // Filter
            child.tables = child.tables!.filter(v => v.key !== tableKey);

            // Update and save
            dispatch(updateOneChild(report, child));

        } catch (error) {
            dispatch(setError(error));
        }
    }

}
