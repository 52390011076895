import * as React from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps } from "react-router-dom";

import { ReportChild } from '../../models/report.model';
import { RootState } from '../../models/root-state.model';
import { findByKey } from '../../services/report.service';
import { getReportByMeeting } from '../../actions/meeting.action';
import { PreviewComponent } from '../common/preview.component';
import { ReportChapterHistory, ReportChaptersComponent, ReportChildHeaderComponent } from '../report';

import { ThreeSplitComponent } from '../ui/container/three-split.component';
import { Constant } from "../../constants";

interface Props {
}

interface TParams {
    meetingId: string
}


export const MeetingPage: React.FC<Props & RouteComponentProps<TParams>> = (props: Props & RouteComponentProps<TParams>) => {

    const { match } = props;

    const dispatch: Dispatch<any> = useDispatch();

    const meetingId = match?.params.meetingId;
    

    // State
   

    // Selector for report
    const report = useSelector((store: RootState) => {
        return store.meeting.report;
    });
    if (!report && meetingId) {
        dispatch(getReportByMeeting(meetingId));
    }

    // --> Selected Child
    const [selectedChild, selectChild] = React.useState<ReportChild | undefined>(undefined);
    if (!selectedChild && report && report.content && report.content.children && report.content.children.length > 0) {
        selectChild(report.content.children[0]);
    }

    React.useEffect(() => {
        // Create request
        const url = new URL(Constant.BASE_MERCURE_URL());
        url.searchParams.append('topic', `http://mycrmeeting.io/meeting/${meetingId}`);   
        // Create sse
        const sse = new EventSource(url.toString());
        sse.onmessage = e => {
            const data: any = JSON.parse(e.data);
            if(data && data.type === Constant.MEETING_SELECTED_CHILD_CHANGE) {
                selectChild(data.payload);
            }
        }
        // Return
        return () => { sse.close(); }
    }, [meetingId, selectChild]);

    // Some selector from remote peer
    useSelector((store: RootState) => {
        const temp = store.meeting.remoteCurrentChild;
        if (temp.key) {
            if (report && report.content && ((selectedChild && selectedChild.key !== temp.key) || !selectedChild)) {
                // Child change
                const child = findByKey(temp.key, report.content.children);
                selectChild(child);
            } else if (selectedChild && (selectedChild.text !== temp.text || selectedChild.state !== temp.state)) {
                // Text of child change
                selectChild({
                    ...selectedChild,
                    text: temp.text,
                    state: temp.state
                });
            }

        }
    });



    return <>
            <div className="container" >
                { 
                    report && <ThreeSplitComponent leftTitle="Chapters" rightTitle="History" >
                                {
                                    {
                                        leftChild:
                                            report && report.content && <ReportChaptersComponent
                                                parent={report.content}
                                                onCreate={() => { }}
                                                onUpdate={() => { }}
                                                onDelete={() => { }}
                                                onChangeOrder={ () => {} }
                                                selectedChild={selectedChild}
                                                onSelectChild={() => { }}
                                            />
                                        ,
                                        mainContent: selectedChild && <>
                                            <ReportChildHeaderComponent child={selectedChild} />
                                            <div className="p-3" >
                                                <PreviewComponent text={selectedChild?.text} />
                                            </div>
                                        </>,
                                        rightChild:
                                            <div className="p-3" >
                                                <ReportChapterHistory
                                                    selectedChildKey={selectedChild ? selectedChild.key : ''}
                                                    snapshots={report && report.content && report.content.history ? report.content.history : []} />
                                            </div>
                                    }
                                }
                            </ThreeSplitComponent>
                }
                {
                    !report &&
                        <>
                            <h2 className="text-center" >
                                Waiting for data ...  <br/><br/> or no data found. Sorry ...
                            </h2>
                        </>
                }
        </div>
    </>


}
