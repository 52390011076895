import { Dispatch } from "react";
import { request } from "../../services/http.service";
import { setError } from "../common.action";

/**
 * 
 * Register an editing session of a specific report
 * 
 * @param id Id of the report
 */
 export const unregisterEditing = (id: number) => {

    return async (dispatch: Dispatch<any>) => {

        try {
            console.log('---------- unregisterEditing');
            await request({
                method: 'DELETE',
                url: `/v1/content/${id}/editing`
            });

        } catch (error) {
            dispatch(setError(error));
        }

    }

}
