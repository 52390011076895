import * as React from "react";


interface Props {
    values: { code: string, label: string}[],
    selected: string,
    setSelectedValue: (selected: string) => void,
    label?: string,
    className?: string
}


export const SelectInputComponent: React.FunctionComponent<Props> = props => {

    const { values, selected, setSelectedValue, className='form-control', label='', ...others } = props;

    const handleSelection = React.useCallback((event : React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setSelectedValue( event.target.value );
    }, [setSelectedValue]);

    return <>
        { label && <label> { label } </label> }
        <select className={ className } onChange={handleSelection}  value={selected} { ...others } >
            { 
                values.map(
                    ({code, label}) => 
                        <option key={code} value={code} > { label } </option>
                )
            }
        </select>
      </>;
}   