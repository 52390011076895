import * as React from "react"
// import { useDispatch } from 'react-redux';
// import { Dispatch } from 'redux';
import { RouteComponentProps } from "react-router-dom";

import { Report, ReportChild, ReportContentHistory } from '../../models/report.model';
import { PreviewComponent } from '../common/preview.component';
import { ReportChapterHistory, ReportChaptersComponent, ReportChildHeaderComponent } from '../report';

import { ThreeSplitComponent } from '../ui/container/three-split.component';
import { ReportExportButtonComponent } from "../report/report-export-button.component";

interface Props {
    id: string,
    report: Report,
    username: string
}

export const SeeReportPage: React.FC<Props & RouteComponentProps> = (props: Props & RouteComponentProps) => {

    // const dispatch: Dispatch<any> = useDispatch();
    const { report, username } = props;

    const snapshots = report?.content?.history;

    // State
    // --> Select snapshot
    const [selectedSnapshot, selectSnapshot] = React.useState<ReportContentHistory|undefined>(undefined);
    if(! selectedSnapshot && snapshots && snapshots.length > 0) {
       selectSnapshot(snapshots[0]);
    }

    // --> Selected Child
    const [selectedChild, selectChild] = React.useState<ReportChild | undefined>(undefined);
    if(! selectedChild && selectedSnapshot && selectedSnapshot.children && selectedSnapshot.children.length > 0 ) {
        selectChild(selectedSnapshot.children[0]);
    }

    // handleChangeSelectedChild
    const handleChangeSelectedChild = React.useCallback(
        (child: ReportChild) => {

            if (timeout.current) {
                clearTimeout(timeout.current);
            }

            // Part 2 : Change Child
            selectChild(child);
        }, []
    );

    // To store timeout between two render
    const timeout = React.useRef<NodeJS.Timeout | null>(null);

    return <>
        { report && selectedSnapshot &&
            <ThreeSplitComponent leftTitle="Chapters" rightTitle="History" >
                {
                    {
                        headerContent: <div className="ml-1 mr-1" >
                            <h1>
                                <div className="float-right" >
                                    { report && selectedSnapshot && 
                                            <ReportExportButtonComponent report={report}  username={ username } snapshotKey={ selectedSnapshot.key } > Export </ReportExportButtonComponent>
                                    }
                                </div>
                                { report.label }
                            </h1>
                        </div>,
                        leftChild:
                            report && selectedSnapshot && <ReportChaptersComponent
                                parent={selectedSnapshot }
                                onCreate={() => { }}
                                onUpdate={() => { }}
                                onDelete={() => { }}
                                selectedChild={selectedChild}
                                onSelectChild={handleChangeSelectedChild}
                                onChangeOrder={() => {} }
                            />
                        ,
                        mainContent: selectedChild && <>
                            <ReportChildHeaderComponent child={selectedChild} />
                            <div className="p-3" >
                                <PreviewComponent text={selectedChild?.text} />
                            </div>
                        </>,
                        rightChild:
                            <div className="p-3" >
                                <ReportChapterHistory
                                    selectedChildKey={selectedChild ? selectedChild.key : ''}
                                    snapshots={report && report.content && report.content.history ? report.content.history.filter(h => h.key !== selectedSnapshot?.key) : []} />
                            </div>
                    }
                }
            </ThreeSplitComponent>
        }
        {
            (!report || !selectedSnapshot ) &&
                <>
                    <h2 className="text-center" >
                        Waiting for data ...  <br/><br/> or no data found. Sorry ...
                    </h2>
                </>
        }

    </>


}
