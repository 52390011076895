import * as React from "react";
import { useDispatch } from 'react-redux';
import { exportContent } from '../../actions/report-content.action';
import { Constant } from '../../constants';
import { LinkButtonComponent } from '../ui/button';
import { ModalComponent } from "../ui/container/modal.component";
import { RowColumnComponent } from "../ui/container/row-colums.component";
import { SelectInputComponent } from "../ui/field/select-input.component";
import { getFilterCallBackForContentChildren } from "../../services/report.service";
import { SnapshotFormComponent, SnapshotInfo } from "../snapshot/snapshot-form.component";
import { CheckBoxInputComponent } from "../ui/field/checkbox-input.component";
import { getWeekNumberAndYear } from "../../services/date.service";
import { AlertComponent } from "../ui/container";
import { Report } from "../../models/report.model";
import { canTakeSnapshot } from "../../services/access.service";
import { addSnapshot } from "../../actions/report-content-history/report-content-add-snapshot.action";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    report: Report,
    username: string
    snapshotKey?: string,
    className?: string
}

// Export Format
const exportFormats = [
    { code: 'xlsx', label: 'Excel (*.xlsx)' },
    { code: 'docx', label: 'Simple Word (*.docx)' },
    { code: Constant.EXPORT_FORMAT_DOCXTEMPLATE, label: 'Template Word (*.docx)' },
    { code: 'md', label: 'MarkDown (*.md)' },
    { code: 'html', label: 'Html (*.html)' }
];

// Export Filter
const exportFilters = [
    { code: Constant.EXPORT_FILTER_ALL, label: 'All' },
    { code: Constant.EXPORT_FILTER_RUNNING, label: 'Running' }
]

export const ReportExportButtonComponent: React.FunctionComponent<Props> = (props: Props) => {

    const dispatch: React.Dispatch<any> = useDispatch();
    
    const { report, username, children, snapshotKey = "" } = props;
    
    // State
    const [error, setError] =  React.useState('');
    // -- ShowModal
    const [showModal, setShowModal] = React.useState(false);
    // -- Selected mode
    const [selectedMode, setSelectedMode] = React.useState(Constant.EXPORT_FORMAT_DOCXTEMPLATE);
    // -- Selected filter
    const [selectedFilter, setSelectedFilter] = React.useState(Constant.EXPORT_FILTER_ALL);
    // -- SnapshotInfo
    const [takeSnapshot, setTakeSnapshot] = React.useState<boolean>(false);
    const [snapshotInfo, setSnapshotInfo] = React.useState<SnapshotInfo>(
        { 
            label: getWeekNumberAndYear(), 
            onlyDifference: true 
        }
    );
    // const [snapshotLabel, setSnapshotLabel] = React.useState<string>("");
    // const [snapshotOnlyDifference, setSnapshotOnlyDifference] = React.useState<boolean>(false);


    const handleSave = React.useCallback(() => {

        const exportFunc = () => {
            dispatch(
                exportContent(
                    report.id,
                    selectedMode,
                    snapshotKey,
                    getFilterCallBackForContentChildren(selectedFilter)
                )
            )
            setShowModal(false);
            setError('');
        };

        if(takeSnapshot) {
            if(snapshotInfo.label.length > 0) {
                // alert(snapshotInfo.label);
                dispatch(
                    addSnapshot(
                        report, snapshotInfo.label, username, 
                        () => { 
                            exportFunc();
                        },
                        (error: string) => setError(error),
                        snapshotInfo.onlyDifference
                    )
                    );

            } else {
                alert('You must set a name to snapshot');
                setError('You must set a name to snapshot')
                return;
            }
        }

        exportFunc();
        
    }, [
        dispatch,
        report,
        username,
        snapshotKey,
        selectedMode,
        selectedFilter,
        takeSnapshot,
        snapshotInfo
    ]);

    return (
        <>
            <LinkButtonComponent onClick={() => setShowModal(true)} > {children} </LinkButtonComponent>
            { showModal
                && <ModalComponent onClose={() => { setShowModal(false) }} onSave={handleSave} title="Export" saveTitle="Export">
                    <p> Choose the export mode </p>
                    <RowColumnComponent >
                        <div className="form-group">
                            <SelectInputComponent values={exportFormats} selected={selectedMode} setSelectedValue={setSelectedMode} label="Mode :" />
                        </div>
                    </RowColumnComponent>
                    <RowColumnComponent>
                        <div className="form-group">
                            <SelectInputComponent values={exportFilters} selected={selectedFilter} setSelectedValue={setSelectedFilter} label="Filter :" />
                        </div>
                    </RowColumnComponent>
                    {
                        canTakeSnapshot(report) 
                            && 
                            <>
                                <RowColumnComponent>
                                    <CheckBoxInputComponent name="takeSnapShot" checked={ takeSnapshot } setChecked={ setTakeSnapshot } label="Take a snapshot" />
                                </RowColumnComponent>
                                <RowColumnComponent>
                                    {
                                        error && <AlertComponent message={error} type="danger" />
                                    }
                                </RowColumnComponent>
                                {
                                    takeSnapshot && <RowColumnComponent>
                                        <SnapshotFormComponent
                                            snapshotInfo={snapshotInfo}
                                            setSnapshotInfo={ setSnapshotInfo }
                                        />
                                    </RowColumnComponent>
                                }
                            </>
                    }
                </ModalComponent>}
        </>

    )
}
