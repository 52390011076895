import * as React from "react"
import { PreviewComponent } from "../common/preview.component";
// import { PreviewComponent } from "../common/preview.component";
// import { RowColumnComponent } from "../ui/container/row-colums.component";
// // import { Dispatch } from "redux"
// // import { useDispatch } from "react-redux"
import { TableEditorComponent } from "../common/tableeditor/table-editor.component";
import { tableEditorFromString, tableEditorToString } from "../common/tableeditor/table-editor.service";
import { TableEditor } from "../common/tableeditor/table.editor.class";

export const TablePage: React.FC<{}> = () => {


    const [tableEditor, setTableEditor] = React.useState<TableEditor>(
        tableEditorFromString(`Tables|Are|Cool\n----|----|----\ncol 3 is|aligned|$1600\ncol 2 is|simple|$12\nzebra stripes|are neat|$1`)
    );


    // const setContent = React.useCallback((content: string) => {
    //     console.log(content);
    // }, []);

    React.useEffect(()=>{

    },[])

    return (
        <>
            <div id="editor" className="table container">
                <h3> Editor </h3>
                <TableEditorComponent
                    id="idDeMonTableau"
                    tableEditor= { tableEditor }
                    setTableEditor= { setTableEditor }
                />
            </div>
            <div id="preview" className="table container" >
                <h3> Preview </h3>
                <PreviewComponent text={ tableEditorToString(tableEditor) } />
            </div>
        </>
    )
}