import * as React from "react"
import moment from "moment";

import { 
    ReportPageHeaderComponent,
    ReportChaptersComponent,
    ReportChapterHistory,
    ReportVarsComponent,
    ReportEditorComponent,
    ReportResourcesComponent
} from '../report';
// import { EditorComponent } from '../common/editor.component';
import { PreviewComponent } from '../common/preview.component';
import { RouteComponentProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ThreeSplitComponent } from "../ui//container/three-split.component";
import { HasChildren, Report, ReportChild } from '../../models/report.model';
// import { addChild, updateChild, deleteChild, patchContent, changeChildOrder } from "../../actions/report-content.action";
import { sendSelectedChildChange, initMeeting, closeMeeting } from "../../actions/meeting.action";
import { SelectorComponent, FooterComponent } from "../ui/container";
import { Constant } from '../../constants';
import { canEdit } from "../../services/access.service";
import { setError } from "../../actions/common.action";
import { findByKey } from "../../services/report.service";
import { ReportChildTableComponent } from "../report/report-child-table.component";
import { updateOneChild } from "../../actions/report-content-child/report-content-update-one-child.action";
import { addOneChild } from "../../actions/report-content-child/report-content-add-one-child.action";
import { deleteOneChild } from "../../actions/report-content-child/report-content-delete-one-child.action";
import { changeOneChildOrder } from "../../actions/report-content-child/report-content-change-order.action";
import { ReportEditingComponent } from "../report/report-editing.component";


// interface TParams {
//     id: string
// }

interface Props {
    id: string,
    report: Report,
    username: string,
    userId: number
}

export const ReportPage: React.FC<Props & RouteComponentProps> = ({history, location, id, report, username, userId }: Props & RouteComponentProps) => {

    const dispatch: Dispatch<any> = useDispatch();
    // --> CanEdit
    if(report) {
        const canEditReport = canEdit(report);
        if(!canEditReport) {
            history.push('/reports');
            dispatch(setError('Access forbidden'));
        }
    }

    // Is there a param
    const paramChild = new URLSearchParams(location.search).get('child');

    // State
    // --> Chapter ?
    const [selectedChild, selectChild] = React.useState<ReportChild | undefined>(
        report?.content?.children 
            ? 
                paramChild ? findByKey(paramChild, report?.content?.children) : report?.content?.children[0] 
            : undefined
        );
    // --> Editor
    const [editor, setEditor] = React.useState<number>(2);


    // handleChapterCreate
    const handleChapterCreate = React.useCallback(
        (name: string, child?: ReportChild) => {
            if (report) { dispatch(addOneChild(report, name, child)); }
        },
        [dispatch, report]
    );

    const handleChapterUpdate = React.useCallback(
        (child: ReportChild) => {
            if (report) { dispatch(updateOneChild(report, child)); }
        },
        [dispatch, report]
    );

    const handleChapterDelete = React.useCallback(
        (parent: HasChildren, child: ReportChild) => {
            if (report && report.content) { dispatch(deleteOneChild(report, parent, child)); }
        },
        [dispatch, report]
    );

    const handleChangeOrder = React.useCallback(
        (child: ReportChild, direction: string) => {
            dispatch(
                changeOneChildOrder(
                    report,
                    child,
                    direction
                )
            );
        }, 
        [dispatch, report]
    );
    
    // The button is unactive                                                  
    const handleOnSave = React.useCallback(() => {
        // if (report) dispatch(patchContent(report));
    }, []); // [report, dispatch]);
    

    // Centralization of some action on the selected child
    // /!\ Update. It's caller role to check if needed
    const updateSelectedChild = React.useCallback((title = '', state = '') => {
        // 
        if(selectedChild) {
            // Text
            selectedChild.date = moment().format(Constant.FORMAT_DATE);
            selectedChild.timespan = Date.now();
            selectedChild.user = username;

            // State
            if(state) {
                selectedChild.state = state;
            }
            if(title) {
                selectedChild.title = title;
            }

            // As a child change (and the selected one)
            // --> Dispatch updateChild
            dispatch(updateOneChild(report, selectedChild));
            // --> Send data to other peer
            // TODO: Change sendSelectedChild to take ReportChild
            dispatch(sendSelectedChildChange(selectedChild));
        }
    }, [report, selectedChild, username, dispatch]);

    // Handle the chand of child state in sub component
    const handleSelectedChildState = React.useCallback((state) => {
        if(selectedChild && selectedChild.state !== state) {
            updateSelectedChild('', state);  
        }
    }, [ selectedChild, updateSelectedChild ]);


    // handleChangeSelectedChild
    const handleChangeSelectedChild = React.useCallback(
        (child: ReportChild) => {

            console.log('HandleChangeSelectedChild', child.key);
            if(timeout.current) {
                clearTimeout(timeout.current);
            }
            //  Change Child
            selectChild(child);
            dispatch(sendSelectedChildChange(child));
        }, [ dispatch]
    );


    // To store timeout between two render
    const timeout = React.useRef<NodeJS.Timeout|null>(null);

    const handleTextChange = React.useCallback(
        (value:string) => {
            if(selectedChild) {

                console.log('handleTextChange', selectedChild.key);
                selectedChild.text = value;

                if(timeout.current) {
                    clearTimeout(timeout.current);
                }
    
                timeout.current = setTimeout(
                    () => { 
                        updateSelectedChild();
                    },
                    1000
                );
            }

        }
    , [updateSelectedChild, selectedChild]);


    const handleOnStartSharing = React.useCallback(() => {
        if (report) {
            dispatch(initMeeting(report));
        }
    }, [report, dispatch]);

    const handleOnStopSharing = React.useCallback(() => {
        if (report) {
            dispatch(closeMeeting());
        }

    }, [report, dispatch]);

    const handleOnSnapShot = React.useCallback(() => {
        history.push(`/report/${id}/snapshots`)
    }, [id, history]);

    const handleOnSearch = React.useCallback(() => {
        history.push(`/report/${id}/search`)
    }, [id, history]);

    const handleOnEditor = React.useCallback((editor) => {
        setEditor(editor);
    }, [])

    return (
        report ? <>
            <div className="report">
                <ThreeSplitComponent leftTitle="Chapters" rightTitle="Properties" >
                    {
                        {
                            headerContent:  <div className="ml-1 mr-1" >
                                                <ReportPageHeaderComponent
                                                    report={report}
                                                    username={username}
                                                    title={report.label}
                                                    onSave={handleOnSave}
                                                    onStartMeeting={handleOnStartSharing}
                                                    onStopMeeting={handleOnStopSharing}
                                                    onSnapShot={handleOnSnapShot}
                                                    onEditor={ handleOnEditor }
                                                    onSearch={ handleOnSearch }
                                                />
                                            </div>,

                            leftChild: report.content
                                && <ReportChaptersComponent
                                    parent={report.content}
                                    onCreate={handleChapterCreate}
                                    onUpdate={handleChapterUpdate}
                                    onDelete={handleChapterDelete}
                                    onChangeOrder={handleChangeOrder}
                                    selectedChild={selectedChild}
                                    onSelectChild={handleChangeSelectedChild}
                                    allowWrite={true}
                                />,
                            mainContent: <>
                                { selectedChild && <ReportEditorComponent 
                                    text={selectedChild.text} 
                                    setText={handleTextChange}
                                    editor={editor}
                                    report={report}
                                    selectedChild={selectedChild} 
                                    changeChildState={ handleSelectedChildState }
                                    />  }     
                            </>,
                            rightChild: <SelectorComponent 
                                            labels={[
                                                'History',
                                                'Resources',
                                                'Tables',
                                                'Variables',
                                                'Preview'
                                            ]}  
                                            keyList={ `sc_${report.id}_` }
                                            data-test-id="reportSelectRight"
                                        >
                                <ReportChapterHistory
                                    key="reportChapterHistory"
                                    selectedChildKey={selectedChild ? selectedChild.key : ''}
                                    snapshots={report && report.content && report.content.history ? report.content.history : []} />
                                <ReportResourcesComponent key="resourceComponent" report={report} />
                                <ReportChildTableComponent key="reportChildComponent" report={ report } child={ selectedChild } />
                                <ReportVarsComponent key="reportVarsComponent" report={report} ></ReportVarsComponent>
                                <PreviewComponent key="previewComponent" text={selectedChild ? selectedChild.text : ''} />
                            </SelectorComponent>
                        }
                    }
                </ThreeSplitComponent>
                { report 
                    && <FooterComponent>
                            <ReportEditingComponent reportId={report.id} title={report.label} userId={userId} childKey={selectedChild?.key} /> 
                        </FooterComponent> 
                    }
            </div>
        </>
            : <div> Not found </div>
    )
}