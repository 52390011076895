import * as React from "react";
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps,  withRouter } from "react-router-dom";
import { RootState } from '../../models/root-state.model';
import { setFrom } from '../../actions/common.action';

export const requireAuth = <P extends object>(Component: React.ComponentType<P>) => {

    const RequireAuth: React.FunctionComponent<P & RouteComponentProps> = (props) => {

        const dispatch: Dispatch<any> = useDispatch();

        const {history, ...other} = props;

        const isLoggedIn: boolean = useSelector((store: RootState) => {
            return store.authentification.isLoggedIn
        })

        React.useEffect(
            () => {
                if(!isLoggedIn) {
                    // Store the current from in the state
                    dispatch( setFrom(history.location.pathname) );
                    history.push('/login');
                }
            },
            [isLoggedIn, history, dispatch]
          )

        return <>
            { isLoggedIn && <Component {...other as P} history={history} /> }
        </>
    }

  return withRouter(RequireAuth);

}