import * as React from "react"

export const InputPage: React.FC<{}> = () => {


    const [ text, setText ] = React.useState("");

    return <>
            <input type="text" value={text} onChange={ e => setText(e.target.value)} />
        </>;


}