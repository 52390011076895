import { Actions } from "./action-type";
import { AnyAction, Dispatch } from 'redux';
import { request } from "../services/http.service";
import { setError } from "./common.action";
import { Report } from "../models/report.model";
import { Resource } from "../models/resource.model";
import { generateMkImageLink } from "../services/resource.service";
import { pasteText } from "../services/paste.service";

export const getReportResources = (
    report: Report
) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            const response = await request({
                method: 'GET',
                url: `/v1/content/${report.id}/ressources`
            });

            dispatch({
                type: Actions.RESOURCE_GET_LIST,
                payload: {
                    report: report,
                    list: response.data
                }
            });

        } catch (error) {
            dispatch(setError(error));
        }
    }
} // /getResource


export const postResource = (
    report: Report,
    type: string,
    file: File,
    filename?: string,
    cb?: () => void
    ) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {

            // Create type
            const typeAsJson = JSON.stringify({ type: type });
            const blob = new Blob(
                [typeAsJson],
                {
                    type: 'application/json'
                }
            );

            // FormData
            const data = new FormData();
            data.append('data', blob);
            data.append('file', file, filename);

            // Request
            const response = await request({
                method: 'POST',
                url:`/v1/content/${report.id}/ressource`,
                data: data,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
                // ,
                // onUploadProgress: (ev: ProgressEvent) => {
                //     // const progress = ev.loaded / ev.total * 100;
                // }
            });

            // Dispatch
            dispatch({
                type: Actions.RESOURCE_UPDATE_ONE,
                payload: {
                    report: report,
                    resource: response.data
                }
            });

            // Copy
            const link = generateMkImageLink(response.data);
            pasteText(link);

            // 
            if(cb) {
                cb();
            }
        } catch (error) {
            dispatch(setError(error));
        }
    }
} // /postResource

export const deleteResource = (
    report: Report,
    resource: Resource
) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {

   
            await request({
                method: 'DELETE',
                url: `/v1/content/${report.id}/ressource/${resource.id}`
            });

            dispatch({
                type: Actions.RESOURCE_DELETE_ONE,
                payload: {
                    report: report,
                    resource: resource
                }
            });

        }catch (error) {
            dispatch(setError(error));
        }
    }

} // /deleteResource