

export const pasteText = (text: string) => {

     // Create the textearea
     const el = document.createElement('textarea');
     // Set value
     el.value = text;
     // Hide
     el.setAttribute('readonly', '');
     el.style.position = 'absolute';
     el.style.left = '-9999px';
     // Append
     document.body.appendChild(el);
     // Select
     el.select();
     // Copy
     document.execCommand('copy');
     // Remove
     document.body.removeChild(el);

}