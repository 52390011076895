import { Dispatch } from "react";
import { Constant } from "../../constants";
import { Report, ReportChild } from "../../models/report.model";
import { setError } from "../common.action";
import { updateAndSaveChild } from "./report-content-update-save-one.action";

/**
 * 
 * Add a new Child to the sélected parent. If no parent, add to the report
 * 
 * @param report Report in which creation is done
 * @param name Name of the children
 * @param child Optional : child
 */
 export const addOneChild = (report: Report, title: string, parent?: ReportChild) => {

    return async (dispatch: Dispatch<any>) => {
        // Where ?
        if (report.content) {
            const { p, parentKey } = parent ? { p: parent, parentKey: parent.key } : { p: report.content, parentKey: Constant.ROOT_PARENT_KEY };
            // let parentKey: string = parent ? parent.key : Constant.ROOT_PARENT_KEY;

            const nextOrder = p.children.reduce((start, child) => {
                if (child.order > start) {
                    return child.order;
                }
                return start;
            }, 0);

            // Create
            const newChild: ReportChild = {
                key: Date.now().toString(),
                timespan: Date.now(),
                title: title,
                text: '',
                children: [],
                date: '',
                user: '',
                state: 'normal',
                parentKey: parentKey,
                order: Math.max(nextOrder + 1, p.children.length + 1) // To avoid user bad manipulation (or bad code ...)
            };

            // Adding
            p.children = [...p.children, newChild];

            // Update
            updateAndSaveChild(
                report, 
                newChild,
                "insert",
                dispatch
                );


        } else {
            dispatch(setError('Report has no content'));
        }

    }

}
