// import * as React from "react";
import { Actions } from "../../actions/action-type";
import { ReportEditingAction, ReportEditingState } from "../../models/report-editing.model";

const initialState: ReportEditingState = {
    reportEditings: {}
}

export const ReportEditingReducer = (
    state: ReportEditingState = initialState,
    action: ReportEditingAction
): ReportEditingState => {

    switch (action.type) {
        case Actions.REPORTEDITING_GET_LIST:
            // Update the list
            const listGet = { ...state.reportEditings };
            listGet[action.payload.id] = action.payload.list!;
            // Update the test
            return { ...state, reportEditings: listGet };

        default:
            return state;
    }
}