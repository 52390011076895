import * as React from "react";
import moment from "moment";

import { AlertComponent, ModalComponent } from ".";
import { Constant } from "../../../constants";

interface PasteImageContainerInterface extends React.StyleHTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    onSave?: (filename: string, file: File, cb: () => void) => void;
    id?: string;
    acceptedFiles?: string[];
}

type BlobLikeFile = File | null;

export const PasteImageContainerComponent: React.FunctionComponent<PasteImageContainerInterface> = (props) => {

    // Explode props
    const {
        onSave,
        children,
        id = 'pasteContainer',
        acceptedFiles = ['image/gif', 'image/png', 'image/jpeg', 'image/bmp'],
        style
    } = props;


    // State
    const [showModal, setShowModal] = React.useState(false);
    const [blob, setBlob] = React.useState<BlobLikeFile>();
    const [url, setUrl] = React.useState<string>('');
    const [error, setError] = React.useState('');

    // Ref to the content div
    const myDiv = React.useRef<HTMLDivElement | null>(null);
    // Text zone of the modal
    const myFileName = React.useRef<HTMLInputElement | null>(null)
    

    // Handle the paste
    const handlePaste = React.useCallback((ev: ClipboardEvent) => {
        // Data ?
        if (ev.clipboardData && ev.clipboardData.items.length > 0) {
            for (let i = 0; i < ev.clipboardData.items.length; i++) {
                // Get the item
                const item = ev.clipboardData.items[i];
                // Valid ?
                if (acceptedFiles.includes(item.type)) {
                    // Yep ... Get the file
                    const blobInternal: BlobLikeFile = item.getAsFile();
                    const src = URL.createObjectURL(blobInternal);
                    // State
                    setBlob(blobInternal);
                    setUrl(src);
                    setShowModal(true);
                    //onPaste(blob);
                }
            }
        } // /if
    }, [acceptedFiles]);

    // Handle the image validation
    const handleSave = React.useCallback(() => {
        if (onSave) {
            // Handle Name
            let filename = `paste ${ moment().format(Constant.FORMAT_DATETIME_SS) }`;
            if(myFileName.current) {
                filename = myFileName.current.value;
            }
            const file: File = blob as File;
            onSave(
                filename,
                file,
                () => {
                    setUrl('');
                    setShowModal(false);
                    setError('');
                }
            );
        }
    }, [blob, onSave]);


    React.useEffect(() => {
        if (myDiv && myDiv.current) {
            const current = myDiv!.current;
            current.onpaste = (ev: ClipboardEvent) => { handlePaste(ev); }

            return () => { current.onpaste = null; }
        }
    }, [myDiv, handlePaste]);



    return <>
        { showModal
            && <ModalComponent onClose={() => { setShowModal(false) }} onSave={handleSave} title="Valid image" >
                <AlertComponent message={error} />
                <p>
                    Image will be upload to server and link set in clipboard (pastebin). Simply call "paste" action on editor to include image (CTRL+V).
                </p>
                <div className="row container form-group">
                    <label className="bmd-label-floating"> FileName </label>
                    <input type="text" className="form-control" defaultValue={ `paste ${ moment().format(Constant.FORMAT_DATETIME_SS) }` } ref={myFileName} />
                </div>
                <div className="text-center" >
                    <img src={url} alt="paste" style={{ maxWidth: '80%', maxHeight:'400px' }} />
                </div>
            </ModalComponent>}
        <div ref={myDiv} id={id} style={style} > {children} </div>
    </>;
} 