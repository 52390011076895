import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import { RootState } from "../../models/root-state.model";
import { IsLoggedInReducer } from './isloggin.reducer';
import { CommonReducer } from "./common.reducer";
import { ReportReducer } from "./report.reducer";
import { MeetingReducer } from "./meeting.reducer";
import { ResourceReducer } from "./resource.reducer";
import { ReportEditingReducer } from "./report-editing.reducer";
import { UACReducer } from "./uac.reducer";

export const globalReducer = combineReducers<RootState>({
    authentification: IsLoggedInReducer,
    form: formReducer,
    common: CommonReducer,
    reports: ReportReducer,
    meeting: MeetingReducer,
    resource: ResourceReducer,
    reportEditing: ReportEditingReducer,
    userAccess: UACReducer
});

