import * as React from "react";
import { useDispatch } from 'react-redux';

import { addTable, editTable, deleteTable, generateContentForEditor } from "../../actions/report-child-table.action";
import { Report, ReportChild, ReportChildTable } from "../../models/report.model";
import { pasteText } from "../../services/paste.service";
import { TableEditorComponent } from "../common/tableeditor/table-editor.component";
import { tableEditorFromString, tableEditorToString } from "../common/tableeditor/table-editor.service";
import { TableEditor } from "../common/tableeditor/table.editor.class";
import { LinkButtonComponent, PrimaryButtonComponent } from '../ui/button';
import { ModalComponent } from "../ui/container";
import { RowColumnComponent } from '../ui/container/row-colums.component';
import { IconBigPlusComponent, IconClipBoardComponent, IconPenComponent, IconTrashComponent } from "../ui/icon";
import { PasteTextButtonComponent } from "../ui/tools/paste-text-button.component";

type ShowModelType = {
    show: boolean;
    table?: ReportChildTable;
}

interface Props {
    report: Report,
    child?: ReportChild
}

export const ReportChildTableComponent: React.FunctionComponent<Props> = (props: Props) => {

    const dispatch: React.Dispatch<any> = useDispatch();

    // Explode Props
    const { report, child } = props;

    // State 
    // -- for the new element
    const [showNewInput, setShowNewInput] = React.useState<boolean>(false);
    // -- for the edit an element
    const [showModal, setShowModal] = React.useState<ShowModelType>({
        show: false
    });

    // New Ref
    const newTableValueRef = React.useRef<HTMLInputElement | null>(null)


    // Handle a click on a new Var valid button
    const onNewValidClick = React.useCallback((e: React.SyntheticEvent) => {
        // 
        e.preventDefault();
        // Add new table
        if (report 
            && child 
            && newTableValueRef.current 
            && newTableValueRef.current.value) {
                dispatch(
                    addTable(report, child, newTableValueRef.current.value )
                );
                setShowNewInput(false);
        }
    }, [dispatch, report, child]);

    // Handle save
    const handleSave = React.useCallback((table: ReportChildTable) => {
        if(report  
            && child
            && table) {
                // Save in pastebin
                pasteText(
                    generateContentForEditor(
                        table
                    )
                );
                // Edit
                dispatch(editTable(report, child, table));
            }
        setShowModal({ show: false, table: undefined });
    }, [ dispatch, report, child ]);


    // Handle a click on delete element
    const onDeleteClick = React.useCallback((code: string) => {
        if (report && child) {
            dispatch(
                deleteTable(report, child, code)
            );
        }
    }, [dispatch, report, child]);

    return <>
        {
            child && <div data-test-id="reportChildTable">
                {
                    child && child.tables && child.tables.map((l, i) => {
                        return <RowColumnComponent className="mb-2" key={`table_${l.key}-${i}`}>
                            <span> {l.label} </span>
                            <div className="text-right" >
                                <PasteTextButtonComponent text={  generateContentForEditor(l) }>
                                    <LinkButtonComponent > <IconClipBoardComponent /> </LinkButtonComponent>
                                </PasteTextButtonComponent>
                                <LinkButtonComponent onClick={() => { setShowModal({ show: true, table: l}) }} > <IconPenComponent /> </LinkButtonComponent>
                                <LinkButtonComponent onClick={() => { onDeleteClick(l.key) }}> <IconTrashComponent /> </LinkButtonComponent>
                            </div>
                        </RowColumnComponent>
                    })
                }
                <RowColumnComponent>
                    <div className="text-right" >
                        <LinkButtonComponent 
                            onClick={() => { setShowNewInput(!showNewInput) }} 
                            data-test-id="btnAddTable"
                            > 
                                <IconBigPlusComponent /> 
                        </LinkButtonComponent>
                    </div>
                </RowColumnComponent>
                {
                    showNewInput &&
                    <div className="input-group mt-3" >
                        <input 
                            type="text"
                            className="form-control"
                            placeholder={'Name'}
                            ref={newTableValueRef}
                            data-test-id="txtTableName"
                            />
                        <div className="input-group-append" >
                            <PrimaryButtonComponent id="new-var" onClick={onNewValidClick} > Ok </PrimaryButtonComponent>
                        </div>
                    </div>
                }
                {
                    showModal.show
                    && <ModalComponent 
                            onClose={() => { setShowModal({ show: false }) }} 
                            onSave={ () => { handleSave(showModal.table!)} }  
                            title="Edit" 
                            saveTitle="Edit">
                        <RowColumnComponent >
                            <ReportChildTableEditComponent table={ showModal.table } />
                        </RowColumnComponent>
                    </ModalComponent>
                }
            </div>
        }
    </>
}


interface ReportChildTableEditProps {
    table?: ReportChildTable
}


const ReportChildTableEditComponent : React.FunctionComponent<ReportChildTableEditProps> = (props: ReportChildTableEditProps) => {

    const { table } = props;

    const [tableEditor, setTableEditor] = React.useState<TableEditor|null>(null)

    // const setContent = React.useCallback((content: string) => {
    //     table!.content = content;
    // }, [table]);

    React.useEffect(() => {
        if(table) {
            setTableEditor(tableEditorFromString(table.content))
        } else {
            setTableEditor(null);
        }

        return () => {
            console.log('FIN !!!!');
        }
    }, [table, setTableEditor]);

    return <>
        {
            table &&
            <>
                <h1> Edit { table.label } </h1>
                { 
                    tableEditor && <TableEditorComponent 
                    id={table.key} 
                    tableEditor={tableEditor} 
                    setTableEditor={ (tableEditor: TableEditor) => { 
                        console.log(tableEditor); 
                        setTableEditor(tableEditor);
                        table.content = tableEditorToString(tableEditor);
                        console.log(table);
                    }  
                }
                /> 
                }
            </>
        }
    </>;
}