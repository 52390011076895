
export class Constant {

    public static BASE_URL = () => {
        return process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API;
    }

    public static BASE_MERCURE_URL = () => {
        const val = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MERCURE : process.env.REACT_APP_PROD_MERCURE;
        return val ? val : '';
    }

    public static APP_VERSION = () => {
        const val = process.env.REACT_APP_VERSION;
        return val ? val : '';
    }

    public static readonly BASE_URL_API = `${Constant.BASE_URL()}/api`;

    public static readonly FORM_ADD_SNAPSHOT = "addSnapshotForm";
    public static readonly FORM_ADD_CONTENT = "addContentForm";

    public static readonly FORMAT_DATE = "YYYY-MM-DD";

    public static readonly FORMAT_TIME = "HH:mm";

    public static readonly FORMAT_DATETIME = `${Constant.FORMAT_DATE} ${Constant.FORMAT_TIME}`;

    public static readonly FORMAT_DATETIME_SS = `${Constant.FORMAT_DATE} ${Constant.FORMAT_TIME}:ss`;

    public static readonly KEY_SPLITTER = '§';

    public static readonly MEETING_SELECTED_CHILD_CHANGE = "MEETING_SELECTED_CHILD_CHANGE";
    public static readonly MEETING_TEXT_CHANGED = "MEETING_TEXT_CHANGED";
    public static readonly MEETING_SET_REPORT = "MEETING_SET_REPORT";

    public static readonly LOCALSTORAGE_TOKEN = "token";

    /**
     * Used when child is a root child
     */
    public static readonly ROOT_PARENT_KEY = "-1";

    public static readonly CHILD_STATE_DONE = "done";
    public static readonly CHILD_STATE_RUNNING = "running";
    public static readonly CHILD_STATE_WAITING = "waiting";

    public static readonly EXPORT_FORMAT_DOCXTEMPLATE = 'docxt';

    public static readonly DIRECTION_UP = 'up';
    public static readonly DIRECTION_DOWN = 'down';

    public static readonly EXPORT_FILTER_ALL = "ALL";
    public static readonly EXPORT_FILTER_RUNNING = "RUNNING";
    public static readonly EXPORT_FILTER_DAYS = "TWODAYS";

    public static readonly DEFAULT_TABLE_MINUS = '----';
    public static readonly DEFAULT_TABLE_CONTENT = `|Col1|Col2|Col3|\n|${Constant.DEFAULT_TABLE_MINUS}|${Constant.DEFAULT_TABLE_MINUS}|${Constant.DEFAULT_TABLE_MINUS}|\n||||\n||||\n||||`;
    
}
