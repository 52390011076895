import * as React from "react"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../models/root-state.model";
import { clearError } from '../../actions/common.action';
import { AlertComponent } from "../ui/container/alert.component";

export const Error: React.FunctionComponent<{}> = () => {

    // Get the dispatch
    const dispatch: React.Dispatch<any> = useDispatch();

    // Get Store from Redux
    const message: string = useSelector(
        (state: RootState) => state.common.error
    );

    // Start timer to Empty message after an amount of time
    React.useEffect(
        () => {
            let timer: any;
            if(message) {
                timer = setInterval(() => {
                  dispatch(clearError());
                  clearTimeout(timer);
                }, 3000)
            }
          return () => clearTimeout(timer)
        },
        [message, dispatch]
    )

    return <>
        { message && <AlertComponent type='danger' message={message} /> }
    </>;
}