
export const getDateNearestHour = () => {
    const date = new Date();

    date.setHours(date.getHours() + Math.round(date.getMinutes()/60));
    date.setMinutes(0, 0, 0); // Resets also seconds and milliseconds

    return date;

}

/**
 * Return the week number with the current year
 * @returns string
 */
export const getWeekNumberAndYear = () : string => {
    return getWeekNumberAndYearFromDate(new Date());
}

/**
 * Return week number and year for the d in parameter
 * @param d Date
 * @returns string
 */
export const getWeekNumberAndYearFromDate = (d: Date) : string => {
    const weekNumber = getWeekNumberFromDate(d);
    const year = d.getFullYear();
    // Build
    return `S${weekNumber} - ${year}`;
}

/**
 * Return the current week number
 * @returns week number
 */
export const getWeekNumber = () : number => {
    return getWeekNumberFromDate(new Date());

}

/**
 * 
 * @param origin param for the calculation
 * @returns Week number for the param
 */
export const getWeekNumberFromDate = (origin: Date): number => {
    // Create a new date to be sure to not 
    // update the origin
    const d = new Date(Date.UTC(origin.getFullYear(), origin.getMonth(), origin.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    // const weekNo = Math.ceil(( ( d.getTime() - yearStart.getTime()) / 86400000) + 1)/7));
    const weekNo = Math.ceil(( ( (d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    // Return
    return weekNo;
}