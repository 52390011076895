import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportEditingList } from "../../actions/report-editing/report-editing-list.action";
import { registerEditing } from "../../actions/report-editing/report-editing-register.action";
import { unregisterEditing } from "../../actions/report-editing/report-editing-unregister.action";
import { getReport } from "../../actions/report.action";
import { Constant } from "../../constants";
import { RootState } from "../../models/root-state.model";
import { SecondaryButtonComponent, SuccessButtonComponent } from "../ui/button";
import { AlertComponent } from "../ui/container";

interface Props extends React.HtmlHTMLAttributes<HTMLElement>{
    title: string,
    reportId: number,
    userId: number,
    childKey?: string
}

interface ChangeInfo {
    change: boolean,
    user?: string,
    type?: string,
    title?: string
}

export const ReportEditingComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    const dispatch: React.Dispatch<any> = useDispatch();

    // Props
    const { title, reportId, userId, childKey, ...others } = props;

    // Get the list from the store
    const list = useSelector((store:RootState) => {
        return store.reportEditing.reportEditings[reportId];
     });

    // Show the refresh button
    const [changeInfo, setChangeInfo] = React.useState<ChangeInfo>({ change: false });

     // To store timeout between two render
     const timeout = React.useRef<NodeJS.Timeout|null>(null);

    // Mercure and get the list -> need the report
    React.useEffect(() => {

        // console.log(' --==  { ReportEditing.useEffect }  ==-- ');

        // -----------------------
        // Listenning about update on the report
        // ------------------------
        // -- Create request
        const url = new URL(Constant.BASE_MERCURE_URL());
        url.searchParams.append('topic', `http://mycrmeeting.io/content/${reportId}`);   
        // -- Create sse
        const sseChange = new EventSource(url.toString());
        sseChange.onmessage = e => {
            // -- Managing the message
            const data: any = JSON.parse(e.data);
            // console.log("Mercure - Change", data);
            if(data.userId !== userId) {
                console.log("Made by someone else");
                if(timeout.current) {
                    clearTimeout(timeout.current);
                }

                setChangeInfo({
                    change: true,
                    user: data.user,
                    type: data.type,
                    title: data.title
                });

    
                timeout.current = setTimeout(
                    () => { 
                        setChangeInfo({ change: false });
                    },
                    5000
                );

            } else {
                console.log("Made by ME");
            }
        }

        // -----------------------
        // Listenning about update off the editing list
        // ------------------------
        // -- Create request
        const url2 = new URL(Constant.BASE_MERCURE_URL());
        url2.searchParams.append('topic', `http://mycrmeeting.io/editing/${reportId}`);   
        // -- Create sse
        const sseEditing = new EventSource(url2.toString());
        sseEditing.onmessage = e => {
            // -- Managing the message
            const data: any = JSON.parse(e.data);
            console.log("Mercure - Editing List", data);
            if(data.userId !== userId) {
                dispatch(getReportEditingList(reportId));
            }
        }
        
        // -------------------------
        // Register
        // -------------------------
        dispatch(registerEditing(reportId));

        // -------------------------
        // Calling to get the list
        // -------------------------
        dispatch(getReportEditingList(reportId));
        
        // Return
        return () => { 
            // Unregister editing
            dispatch(unregisterEditing(reportId));
            // Close the sse on change
            sseChange.close(); 
            sseEditing.close();
        }
    }, [reportId, userId, dispatch]);

    
    const handleRefresh = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        dispatch(getReport(reportId));
        setChangeInfo({ change: false }); 
    }, [reportId, dispatch]);


    // Component
    return <>
            <div className="text-center container" { ...others } >
                <small> 
                        {
                            changeInfo && changeInfo.change && <>
                                <AlertComponent type="info" message={ `Change made ${changeInfo.user} [${changeInfo.type} ${changeInfo.title}]  ` } >
                                    <SuccessButtonComponent 
                                        className="btn-sm" 
                                        onClick={ handleRefresh }
                                    > 
                                        Refresh 
                                    </SuccessButtonComponent>
                                    <SecondaryButtonComponent 
                                        className="btn-sm" 
                                        onClick={ () => { setChangeInfo({ change: false }); } }
                                    > 
                                        Close 
                                    </SecondaryButtonComponent>
                                </AlertComponent>
                            </>
                        }
                        <span>{ title } - <b>[userId : { userId }]</b> - <b> [{ childKey }] </b>- Number of session : {  list ? list.length  : '0' }  </span>
                </small>                
            </div>
        </>;
});