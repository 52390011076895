import * as React from "react";
import { Dispatch } from 'redux';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';

import { ModalComponent } from "../ui/container/modal.component";

import { Report, ReportLight } from "../../models/report.model";

import { postNewReport } from "../../actions/report.action";
import { AlertComponent } from '../ui/container/alert.component';

import { ReportComponentForm } from "./report.form";
import { Constant } from '../../constants';
import { IconPlusComponent } from "../ui/icon";
import { LinkButtonComponent } from "../ui/button";

interface Props {
}

export const ReportAddButtonComponent: React.FunctionComponent<Props> = () => {
    
    const [showModal, setShowModal] = React.useState(false);
    const [error, setError] =  React.useState('');
    const dispatch: Dispatch<any> = useDispatch();

    const handleSave = () => {
        dispatch(submit(Constant.FORM_ADD_CONTENT))
    }

    const report: Report = {
        id: -1,
        label: "",
        description: "",
        accessMode: "OWNER"
    }

    const onSubmit = (form:ReportLight) => {
        dispatch(
            postNewReport(
                form,
                () => { setShowModal(false); },
                (error: string) => setError(error)
            )
        );
    }

    return <>
        { showModal 
            && <ModalComponent 
                    onClose={ () => { setShowModal(false) } } 
                    onSave={ handleSave } 
                    title="Add new report" 
                    closeTitle="Cancel"
                    saveTitle="New"
                > 
                    <AlertComponent message={error} />
                    <ReportComponentForm initialValues={report} onSubmit={onSubmit} />
                </ModalComponent> }
        <LinkButtonComponent onClick={() => setShowModal(! showModal)} className="btn-lg"> <IconPlusComponent /> </LinkButtonComponent>
    </>;

}




