import * as React from "react";
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Constant } from '../../constants';
import { Report } from '../../models/report.model';
import { FieldInputComponent } from '../ui/field/field-input.component';


export interface IFormReportData {
    id: number,
    label:string;
    description:string;
}

interface IErrorsForm {
    label?:string;
    description?:string;
}
  
const formReportValidation = (values:IFormReportData) => {
    const errors:IErrorsForm = {};

    if(!values.label) {
    errors.label = "Please provide label";
    }

    if(!values.description) {
    errors.description = "Please provide description";
    }

    return errors;
};

interface IFormProps {
    initialValues?: Report
}

interface IFormDispatchProps {
    onSubmit: (form:IFormReportData) => void
}
  
const ReportComponentBase: React.FunctionComponent<IFormProps & IFormDispatchProps & InjectedFormProps<IFormReportData, IFormProps & IFormDispatchProps>> = (props) => {

    const { handleSubmit, onSubmit } = props;

    const manageSubmit = onSubmit;

    return <form onSubmit={handleSubmit(manageSubmit)} >
        <Field
            name="label"
            component= { FieldInputComponent }
            type="text"
            label="Label"
            required={true}
            placeholder="Choose a label"
        />
        <Field
            name="description"
            component={FieldInputComponent}
            type="text"
            label="Description"
            placeholder="A small text to remember why this report"
            required={true}
        />
    </form>
};
  
const ReportComponentReduxForm = reduxForm<IFormReportData, IFormProps & IFormDispatchProps>({
    form: Constant.FORM_ADD_CONTENT,
    validate: formReportValidation,
})(ReportComponentBase);
  
export const ReportComponentForm = connect()(ReportComponentReduxForm);