import { Constant } from "../../../constants";
import { TableEditor } from "./table.editor.class";

export const tableEditorFromString = (content: string): TableEditor => {
    // Split by \n
    const tmpLines = content.split("\n");
    // Split each lines by |
    const lines = tmpLines.map(
        (value: string) => { 
            if(value.startsWith("|")) {
                value = value.substring(1).slice(0,-1);
            }
            return value.split("|") 
    });
    // Init the table
    const te = new TableEditor(lines);
    // return
    return te;
}

export const tableEditorToString = (tableEditor: TableEditor) : string => {

    // Init
    let str = "";

    // If no header = no string
    if(! tableEditor.hasHeader()) {
        return str;
    }

    // Header
    const header = tableEditor.header();
    str += `|${header!.join('|')}|`; // Test at the beginning, there is an header
    str += `\n|${header!.map(c => Constant.DEFAULT_TABLE_MINUS).join('|')}|`;
        
    // If not row, this is the end
    if(! tableEditor.hasRows()) {
        return str;
    }

    // Rows
    const rows = tableEditor.rows();
    rows!.forEach(row => {
            str += `\n|${row.join('|')}|`;
    });

    // console.log(str);
    return str;
}