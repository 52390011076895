import * as React from "react"
// import { Dispatch } from "redux"
// import { useDispatch } from "react-redux"


export const ProfilePage: React.FC<{}> = () => {
//   const dispatch: Dispatch<any> = useDispatch()

//   const deleteArticle = React.useCallback(
//     (article: IArticle) => dispatch(removeArticle(article)),
//     [dispatch, removeArticle]
//   )

  return (
    <div className="profile">
        <h1> Profile </h1>
    </div>
  )
}