import * as actions from "./action-type";
import { AnyAction, Dispatch } from 'redux';
import { Report, ReportAction, ReportChild, HasChildren, ReportVar } from '../models/report.model';
import { setError } from './common.action';
import { request } from "../services/http.service";
import { RootState } from '../models/root-state.model';
import { Constant } from '../constants';
import { filterContentChildren, findByKey } from '../services/report.service';


/**
 * 
 * Add a new Child to the sélected parent. If no parent, add to the report
 * 
 * @param report Report in which creation is done
 * @param name Name of the children
 * @param child Optional : child
 */
export const addChild = (report: Report, title: string, parent?: ReportChild) => {

    return async (dispatch: Dispatch<any>) => {
        // Where ?
        if (report.content) {
            const { p, parentKey } = parent ? { p: parent, parentKey: parent.key } : { p: report.content, parentKey: Constant.ROOT_PARENT_KEY };
            // let parentKey: string = parent ? parent.key : Constant.ROOT_PARENT_KEY;

            const nextOrder = p.children.reduce((start, child) => {
                if(child.order > start) {
                    return child.order;
                }
                return start;
            }, 0);

            // Create
            const newChild: ReportChild = {
                key: Date.now().toString(),
                timespan: Date.now(),
                title: title,
                text: '',
                children: [],
                date: '',
                user: '',
                state: 'normal',
                parentKey: parentKey,
                order: Math.max(nextOrder+1, p.children.length + 1) // To avoid user bad manipulation (or bad code ...)
            };

            // Adding
            p.children = [...p.children, newChild];

            // Update and save
            updateAndSaveReport(report, dispatch);


        } else {
            dispatch(setError('Report has no content'));
        }

    }

}

export const updateChild = (report: Report, child: ReportChild) => {
    return async (dispatch: Dispatch<any>) => {
        // Where ?
        if (report.content) {

            // Find the parent ?
            let p = report.content as HasChildren;
            if (child.parentKey !== Constant.ROOT_PARENT_KEY) {
                p = findByKey(child.parentKey, report.content!.children) as HasChildren;
            }

            // If p
            if (p) {
                p.children = p.children.map(c => c.key === child.key ? child : c)
            }

            // Update and save
            updateAndSaveReport(report, dispatch);


        } else {
            dispatch(setError('Report has no content'));
        }

    }
}

/**
 * 
 * @param report Report to be changed
 * @param data Data. Should be remoteCurrentChild in meeting.model
 */
export const updateChildFromPeer = (report: Report, data: any) => {

    return async (dispatch: Dispatch<any>) => {

    }

}

/**
 * Delete a child from its parent
 * 
 * @param report The selected report
 * @param parent The parent node where we delete node
 * @param child The deleted child
 */
export const deleteChild = (report: Report, parent: HasChildren, child: ReportChild) => {
    return async (dispatch: Dispatch<any>) => {
        // Where ?
        if (report.content) {


            // Update children
            let i = 0;
            parent.children = parent.children.filter(
                c => {
                    if(c.key !== child.key) {
                        c.order = i;
                        i++;
                        return c;
                    }
                    return null;
                }
            );

            // Update and save
            updateAndSaveReport(report, dispatch);

        } else {
            dispatch(setError('Report has no content'));
        }

    }
}


/**
 * Change child order based on direction (simple way)
 * @param report 
 * @param child
 */
export const changeChildOrder = (report: Report, child: ReportChild, direction: string) => {

    return async (dispatch: Dispatch<AnyAction>) => {
        // First we need the parent children collection
        let p = report.content as HasChildren;
        if (child.parentKey !== Constant.ROOT_PARENT_KEY) {
            p = findByKey(child.parentKey, report.content!.children) as HasChildren;
        }
        const children = p.children;
        
        // Index of child in the list
        const index = children.findIndex(c => c.key === child.key);

        // Index of the child to change order
        // /!\ : Direction are based on user action which is the reverse of
        // order !
        let indexChange = index + 1;
        if(direction === Constant.DIRECTION_UP) {
            indexChange = index - 1;
        }

        // Check
        if((indexChange < 0) || (indexChange > children.length -1)) {
            return;
        }

        // Get the child
        const childChange = children[indexChange];

        // Change order
        const order = child.order;
        child.order = childChange.order;
        childChange.order = order;

        // Set
        p.children = [...children];

        // Save and update
        updateAndSaveReport(report, dispatch);
    }

} // /changeChildOrder

/**
 * Patch the content to the server. All Data !
 *  * 
 * @param report 
 */
export const patchContent = (report: Report) => {

    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            await request({
                method: 'PATCH',
                url: `/v1/content/${report.id}`,
                data: {
                    'content': report.content
                }
            });

        } catch (error) {
            dispatch(setError(error));
        }
    }
}

/**
 * 
 * Ask the server to export content
 * 
 * @param reportId Id of the report which need to be exported
 * @param format Which format ?
 */
export const exportContent = (
    reportId: number,
    format: string, 
    snapshotKey: string = "",
    filterCallback: (child: ReportChild) => boolean = (child: ReportChild) => { return true; }
    ) => {

    return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {

        try {

            // Find the report
            const report = getState().reports.reports.find(r => r.id === reportId);
            if (!report) {
                throw new Error(`No report found : ${reportId}`);
            }

            // Prepare data
            const data = {
                ...report,
                content: { 
                    ...report.content,
                    // No need to send back history
                    history: [],
                    children: snapshotKey 
                            ? report.content?.history.find(h => h.key === snapshotKey)?.children
                            : report.content?.children 
                }
            }

            // // Filter
            if(data.content.children) {
                data.content.children = filterContentChildren(data.content.children as ReportChild[], filterCallback);
            }


            // Prepare request
            let url = `/v1/export/${format}`;
            if (format === Constant.EXPORT_FORMAT_DOCXTEMPLATE) {
                url = '/v1/export/docx/template';
                format = 'docx';
            }

            // Request
            const response = await request({
                method: 'POST',
                url: url,
                responseType: 'blob',
                data: data
            });

            // Handle response
            if (response) {
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${report.label}.${format}`); //any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            }

        } catch (error) {
            dispatch(setError(error));
        }
    }
}

/**
 * Add a new var to the report
 * @param report Report
 * @param code Code of the var
 * @param label Label of the var
 */
export const addVar = (report: Report, code: string, value: string) => {

    return async (dispatch: Dispatch<any>) => {
        try {
            // Create the new var 
            const newVar: ReportVar = {
                code, value
            };

            // Add
            report.content!.vars = [
                ...report.content!.vars,
                newVar
            ];

            // Update and save
            updateAndSaveReport(report, dispatch);

        } catch (error) {
            dispatch(setError(error));
        }
    }

}

/**
 * Edit the value a var
 * @param report Report
 * @param code Code of the var
 * @param label Label of the var
 */
export const editVar = (report: Report, code: string, value: string) => {

    return async (dispatch: Dispatch<any>) => {
        try {
            // Create the new var 
            const editVar: ReportVar = {
                code, value
            };

            // Edit
            report.content!.vars = report.content!.vars.map(v => v.code === code ? editVar : v);

            // Update and save
            updateAndSaveReport(report, dispatch);

        } catch (error) {
            dispatch(setError(error));
        }
    }
}

/**
 * Delete a var in the report
 * @param report 
 * @param code 
 */
export const deleteVar = (report: Report, code: string) => {

    return async (dispatch: Dispatch<any>) => {
        try {

            // Filter
            report.content!.vars = report.content!.vars.filter(v => v.code !== code);

            // Update and save
            updateAndSaveReport(report, dispatch);

        } catch (error) {
            dispatch(setError(error));
        }
    }

}

const updateAndSaveReport = (report: Report, dispatch: Dispatch<any>) => {

    // Clone report
    report = { ...report }

    dispatch({
        type: actions.PUT_UPDATE_CONTENT,
        reports: [report]
    } as ReportAction);

    dispatch(patchContent(report));
}