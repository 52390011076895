import { Dispatch } from "react";
import { HasChildren, Report, ReportChild } from "../../models/report.model";
import { setError } from "../common.action";
import { updateAndSaveChild } from "./report-content-update-save-one.action";

/**
 * Delete a child from its parent
 * 
 * @param report The selected report
 * @param parent The parent node where we delete node
 * @param child The deleted child
 */
 export const deleteOneChild = (
    report: Report, 
    parent: HasChildren,
    child: ReportChild
) => {
    return async (dispatch: Dispatch<any>) => {
        // Where ?
        if (report.content) {


            // Update children
            let i = 0;
            parent.children = parent.children.filter(
                c => {
                    if(c.key !== child.key) {
                        c.order = i;
                        i++;
                        return c;
                    }
                    return null;
                }
            );

            // Update and save
            // --> Must be the child
            updateAndSaveChild(report, child, "delete", dispatch);

        } else {
            dispatch(setError('Report has no content'));
        }

    }
}