import * as React from "react";
import { Report } from '../../models/report.model';
import { Dispatch } from 'redux';
// import { Field,  InjectedFormProps, reduxForm, submit } from 'redux-form';
import { useDispatch } from 'react-redux';

// import { DangerButtonComponent } from "../ui/button/button-danger.component";
import { deleteReport } from '../../actions/report.action';
import { IconTrashComponent } from "../ui/icon";
import { LinkButtonComponent } from "../ui/button";
// import { FieldInputComponent } from '../ui/field/field-input.component';

// import { ReportLight } from "../../models/report.model";

// import { postNewReport } from "../../store/actions/report.action";
// import { AlertComponent } from '../ui/container/alert.component';

interface Props {
    report: Report
}

export const ReportDeleteButtonComponent: React.FunctionComponent<Props> = (props) => {

    const dispatch: Dispatch<any> = useDispatch();
    
    const handleClick = (e:React.SyntheticEvent) => {
        e.preventDefault();
        if(window.confirm('Are-you sure ?')) {
            dispatch(
                deleteReport(
                    props.report
                )
            );
        }
    }

    return <>
        <LinkButtonComponent onClick={handleClick} title="Delete" > <IconTrashComponent /> </LinkButtonComponent>
    </>;
}
