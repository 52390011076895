import { Report, ReportAction, ReportContentHistory } from "../../models/report.model";
import { AnyAction, Dispatch } from 'redux';
import * as lodash from "lodash";
import * as actions from "../action-type";
import { cloneForSnapshot } from '../../services/report.service';
import { request } from "../../services/http.service";
import { setError } from "../common.action";


/**
 * Create a snapshot from the actual content of the report
 */
export const addSnapshot = (
    report: Report,
    title: string,
    username: string,
    successCallBack: any,
    errorCallBack: any,
    onlyDiff: boolean = false
) => {
    return async (dispatch: Dispatch<any>) => {

        try {
            // To avoid a big error ...
            if (!report.content) { return; }

            // Take the children of the report to create a clone for history
            const children = cloneForSnapshot(report, onlyDiff);

            // create the new history
            const historyContent: ReportContentHistory = {
                key: Date.now().toString(),
                timespan: Date.now(),
                title: title,
                user: username,
                children: children,
                type: onlyDiff ? 'diff' : 'complete'
            }

            // Clone the report
            const updatedReport = lodash.cloneDeep(report);

            // Add History
            // --> directly an add as its already a clone
            updatedReport.content?.history.unshift(historyContent);

            // Save on network
            // dispatch(patchContent(updatedReport))
            dispatch(
                postHistory(
                    report,
                    historyContent
                )
            );

            successCallBack();

            // Dispatch
            dispatch({
                type: actions.PUT_UPDATE_CONTENT,
                reports: [updatedReport]
            } as ReportAction);

        } catch (error) {
            console.error(error);
            if (error && error.message) { errorCallBack(error.message); }
        }
    }

}

/**
 * Patch the content to the server. All Data !
 *  * 
 * @param report 
 */
 const postHistory = (
    report: Report,
    history: ReportContentHistory
) => {

    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            await request({
                method: 'POST',
                url: `/v1/content/${report.id}/history`,
                data: {
                    'history': history
                }
            });

        } catch (error) {
            dispatch(setError(error));
        }
    }
}
