import * as React from "react";
import { Dispatch } from 'redux';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';

import { ModalComponent } from "../ui/container/modal.component";
import { PrimaryButtonComponent } from "../ui/button/button-primary.component";

import { Report } from "../../models/report.model";

import { AlertComponent } from '../ui/container/alert.component';

import { SnapshotComponentForm, IFormSnapshotData } from "./snapshot.form";
import { Constant } from '../../constants';
import { addSnapshot } from "../../actions/report-content-history/report-content-add-snapshot.action";
import { getWeekNumberAndYear } from "../../services/date.service";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    report: Report,
    username: string
}

export const SnapshotAddButtonComponent: React.FunctionComponent<Props> = ({ report, username, ...other }) => {
    
    const dispatch: Dispatch<any> = useDispatch();

    
    const [showModal, setShowModal] = React.useState(false);
    const [error, setError] =  React.useState('');

    const handleSave = React.useCallback( () => {
        dispatch(submit(Constant.FORM_ADD_SNAPSHOT))
    }, [dispatch] );
    
    const manualSetError = React.useCallback((error) => {
        setError(error);
    }, []);
    
    const manualSetShowModal = React.useCallback(() => {
        setShowModal(s => !s);
    }, []);

    /**
     * The real submit function
     */
    const onModalNewSubmit = React.useCallback((form: IFormSnapshotData) => {
        if (!report) { return; }
        dispatch(
            addSnapshot(
                report, form.label, username, 
                () => { manualSetShowModal(); },
                (error: string) => manualSetError(error),
                form.onlyDiff
            )
            );
        
    }, [report, username, manualSetError, manualSetShowModal ,dispatch]);



    return <>
        { showModal 
            && <ModalComponent onClose={manualSetShowModal} onSave={ handleSave } title="Add new snapshot" saveTitle="Save"> 
                    <AlertComponent message={error} />
                    <SnapshotComponentForm onSubmit={onModalNewSubmit} initialValues={{ label: getWeekNumberAndYear(), onlyDiff:true }} ></SnapshotComponentForm>                    
                </ModalComponent> }
        <PrimaryButtonComponent {...other } onClick={manualSetShowModal}> New </PrimaryButtonComponent>
    </>;

}




