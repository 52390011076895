import { Dispatch } from "react";
import { AnyAction } from "redux";
import { request } from "../../services/http.service";
import { Actions } from "../action-type";
import { setError } from "../common.action";


/**
 * Get the list of current editing for the report
 * @param report 
 * @returns 
 */
export const getReportEditingList = (
    id: number
) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            const response = await request({
                method: 'GET',
                url: `/v1/content/${id}/editing`
            });

            dispatch({
                type: Actions.REPORTEDITING_GET_LIST,
                payload: {
                    id: id,
                    list: response.data
                }
            });

        } catch (error) {
            dispatch(setError(error));
        }
    }
} // /getResource


