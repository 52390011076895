import * as React from "react"
import { RouteComponentProps } from 'react-router';


import { Report, ReportChild } from '../../models/report.model';
import { ReportChaptersComponent, ReportChapterHistory } from "../report";
import { PreviewComponent } from '../common/preview.component';
import { ThreeSplitComponent } from "../ui//container/three-split.component";

interface Props {
    id: string,
    report: Report,
    username: string
}


export const ViewReportPage: React.FC<Props & RouteComponentProps> = ({match, history, location, id, report, username} : Props & RouteComponentProps) => {


    // const dispatch: Dispatch<any> = useDispatch();

    const [selectedChild, selectChild] = React.useState<ReportChild | undefined>(undefined);
    if(! selectedChild && report && report.content && report.content.children && report.content.children.length > 0 ) {
        selectChild(report.content.children[0]);
    }

    return <>
        <div className="container"> <h1> { report?.label }  </h1> </div>
        <ThreeSplitComponent leftTitle="Chapters" rightTitle="History" >
            {
                {
                    leftChild: 
                        report && report.content && <ReportChaptersComponent
                        parent={report.content}
                        onCreate={() => { }}
                        onUpdate={() => { }}
                        onDelete={() => { }}
                        selectedChild={selectedChild}
                        onSelectChild={selectChild}
                        onChangeOrder={ () => {} }                                                                           
                        />
                    ,
                    mainContent:  selectedChild && <PreviewComponent text={selectedChild?.text} />,
                    rightChild:  <ReportChapterHistory 
                                        selectedChildKey={ selectedChild ? selectedChild.key : '' } 
                                        snapshots={ report && report.content && report.content.history ? report.content.history : [] } />
                }
            }
        </ThreeSplitComponent>
    </>


}
