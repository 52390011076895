import { Actions } from "../../actions/action-type";
import { MeetingState } from "../../models/meeting.model";


const initialState: MeetingState = {
    meeting: false,
    id: "",
    remoteCurrentChild: { key: '', text: '', state: '' },
    report: undefined
}

export const MeetingReducer = (
    state: MeetingState = initialState,
    action: { type: string, payload: any }
): MeetingState => {
    switch (action.type) {
        case Actions.MEETING_SET_ID:
            return {
                ...state,
                id: action.payload,
                meeting: true
            }
        case Actions.MEETING_SELECTED_CHILD_CHANGE:
            return {
                ...state,
                remoteCurrentChild: action.payload
            }
        case Actions.MEETING_SET_REPORT: {
            return {
                ...state,
                report: action.payload
            }
        }
        case Actions.MEETING_END : {
            return {
                ...initialState
            }
        }
        default:
            return state;
    }
}