import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import { LightButtonComponent, LinkButtonComponent } from '../ui/button';
import { Report, ReportSearchResult } from '../../models/report.model';
import { InputTextWithButtonComponent } from "../ui/container";
import { searchInReport } from "../../services/report.service";
import { PreviewComponent } from "../common/preview.component";
import { IconBackComponent, IconEyeComponent, IconSearchComponent } from "../ui/icon";


interface Props {
    id: string,
    report: Report,
    username: string
}

export const SearchPage: React.FC<Props & RouteComponentProps> = ({ report }: Props & RouteComponentProps) => {
    
    // State
    // --> Results
    const [results, setResults] = React.useState<ReportSearchResult[]>([]);

    // Ref to the input field
    const myRef = React.useRef<HTMLInputElement | null>(null)

    // Handle the search
    const handleSearchClick = React.useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        if(report && myRef.current) {
            const temp = searchInReport(report, myRef.current!.value, true);
            if(temp) {
                setResults(temp);
            } else {
                setResults([]);
            }
        }
    }, [report]);
    
   
    
     return <>
        { report && 
                <div className="container" >
                    <h2 className="mb-5">
                        <div className="float-right form-inline" >
                            <InputTextWithButtonComponent onValidClick={ handleSearchClick } inputRef={myRef} > <IconSearchComponent  /> </InputTextWithButtonComponent>
                        </div>
                        <Link to={`/report/${report?.id}`} >
                            <LinkButtonComponent > <IconBackComponent /> </LinkButtonComponent>
                        </Link>
                        Search in { report.label }
                    </h2>
                    { (results 
                        && results.length > 0) ?
                            results.map((r, i) => <SearchResultComponent result={r} key={i} />)
                            : <h4 className="text-center mt-5"> No result.</h4>
                    }
                </div>
        }
    </>;
}

interface SearchResultProps {
    result: ReportSearchResult
}

const SearchResultComponent: React.FC<SearchResultProps> = React.memo((props: SearchResultProps) => {
    // Read props
    const { result } = props;


    // Manage text presentation
    const textPresentation = React.useCallback(() => {

        // Init
        let text = '';
        let index = 0;
        const length = 30;
        
        // Split the text
        while(index >=0 && index < result.child.text.length - 1) {
            // Search
            index = result.child.text.indexOf(result.search, index);
            if(index >= 0) {
                // Get the start index and take the 30 characters 
                const startIndex = index - length > 0 ? index - length : 0;
                // Get the end index
                const endIndex = index + length < result.child.text.length - 1 ? index + length : result.child.text.length - 1;
                // Update text
                text = `${text}[...] ${result.child.text.substring(startIndex, endIndex )} [...]`
                // Next
                index++;
            }
        }

        // Highlight
        return text.replace(result.search, `<mark>${result.search}</mark>`).replace('___','');

    }, [result]);

    // Get link to the element
    const getLink = React.useCallback(() => {
        switch (result.type) {
            case 'snapshot':
                return `/report/${result.reportId}/snapshots?key=${result.snapshotKey}&child=${result.child.key}`;        
            default:
                return `/report/${result.reportId}?child=${result.child.key}`
        }
    }, [result]); 

    return <>
        <div className="mt-3 mb-5" >
            <hr/>
            <h4> 
                <Link to={getLink()}  >
                    <LightButtonComponent > <IconEyeComponent /> </LightButtonComponent>
                </Link> 
                { result.title } - { result.child.title }
            </h4>
            <hr/>
            <div style={{backgroundColor: "lightgray" }} >
                {/* <PreviewComponent text={result.child.text} /> */}
                <PreviewComponent text={textPresentation()} />
            </div>
        </div>
    </>;
});