import * as React from "react"
import { InputTextWithButtonComponent } from "../../ui/container";
import {
    Menu,
    Item,
    Separator,
    useContextMenu,
    TriggerEvent,
    PredicateParams,
    ItemParams
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { TableEditor } from "./table.editor.class";

// Type of action on the context menu
enum TableEditorActionType {
    RowAddBefore = 'rowAddBefore',
    RowAddAfter = 'rowAddAfter', 
    RowDelete = 'rowDelete', 
    ColumnAddBefore = 'columnAddBefore', 
    ColumnAddAfter = 'columnAddAfter',
    ColumnDelete = 'columnDelete'
} 

interface TableEditorCellEvent {
    type: TableEditorEventType;
    cell: CellCoordinate;
    e: React.SyntheticEvent | TriggerEvent
    data: string;
}


interface TableEditorProps {
    id: string,
    // content: string;
    // setContent: (content: string) => void
    tableEditor: TableEditor
    setTableEditor: (tableEditor : TableEditor) => void
}


interface TableEditorRowComponentProps {
    data: string[];
    onEvent: (e: TableEditorCellEvent) => void;
    row: number;

}

interface CellCoordinate {
    row: number;
    column: number;
}

interface TableEditorCellComponentProps {
    cell: CellCoordinate;
    value: string,
    onEvent: (e: TableEditorCellEvent) => void
}

// Event type from cell
enum TableEditorEventType {
    CellSelect = 'select',
    CellContextMenu = 'contextmenu',
    CellEdit =  'edit',
    CellChange =  'change'
}



interface MenuProps {
    cell: CellCoordinate;
}

type ItemData = string;

// ------------------------------------------------
// Function to manage if a menu should be activated
// ------------------------------------------------
const shouldDisabledMenuItem = ({
    props,
    data,
    triggerEvent,
  }: PredicateParams<MenuProps, ItemData>) => {

    switch (data) {
        case TableEditorActionType.RowAddBefore:
            return props!.cell.row < 0;
        case TableEditorActionType.RowAddAfter:
                return props!.cell.row < 0;
        case TableEditorActionType.RowDelete:
            return props!.cell.row < 0;
        case TableEditorActionType.ColumnAddBefore:
            return props!.cell.column < 0;
        default:
            return false;
    }
}

// ------------------------------------------------
// The main component : Table
// ------------------------------------------------
export const TableEditorComponent: React.FunctionComponent<TableEditorProps> = (props: TableEditorProps) => {

    // Variables
    const { id, tableEditor, setTableEditor } = props;

    // Prepare context menu
    const { show } = useContextMenu({
        id: `menu_tab_${id}`
    });

    // Handle an event from a cell
    const handleEvent = React.useCallback((e: TableEditorCellEvent) => {
        switch (e.type) {
            case TableEditorEventType.CellContextMenu:
                show((e.e as TriggerEvent), {props : {cell : e.cell}});
                break;
            case  TableEditorEventType.CellChange:
                if (e.cell.row === -1) {
                    setTableEditor(tableEditor.updateHeader(e.cell.column, e.data));
                } else {
                    setTableEditor(tableEditor.updateRow(e.cell.row, e.cell.column, e.data));
                }
                break;
            default:
                break;
        }
    }, [tableEditor, setTableEditor, show]);

    // Handle a context menu click
    const handleItemContextClick = React.useCallback(({
        event,
        props,
        data,
        triggerEvent,
      }: ItemParams<MenuProps, ItemData>) => {
            switch (data) {
                case TableEditorActionType.RowAddAfter:
                    setTableEditor(tableEditor.addRowAfter(props!.cell.row));
                    break;
                case TableEditorActionType.RowAddBefore:
                    setTableEditor(tableEditor.addRowBefore(props!.cell.row));
                    break;
                case TableEditorActionType.RowDelete:
                    setTableEditor(tableEditor.removeRow(props!.cell.row));
                    break;
                case TableEditorActionType.ColumnAddAfter:
                    setTableEditor(tableEditor.addColumnAfter(props!.cell.column));
                    break;
                case TableEditorActionType.ColumnAddBefore:
                    setTableEditor(tableEditor.addColumnBefore(props!.cell.column));
                    break;    
                case TableEditorActionType.ColumnDelete:
                    setTableEditor(tableEditor.removeColumn(props!.cell.column));
                    break;    
                default:
                    break;
            }
      }, [tableEditor, setTableEditor]);

    return <>
        {
            tableEditor
            &&
            <>
                <table className="table table-bordered" >
                    {
                        tableEditor.hasHeader() && <thead><TableEditorHeaderComponent data={tableEditor.header()!} onEvent={handleEvent} row={-1} /></thead>
                    }
                    <tbody>
                        {
                           tableEditor.hasRows()
                            && tableEditor.rows()!.map((row: string[], index: number) => <TableEditorRowComponent data={row} row={index} onEvent={handleEvent} key={`tab${index}`} />)
                        }
                    </tbody>
                </table>
                <Menu id={`menu_tab_${id}`}>
                    <Item 
                        disabled={shouldDisabledMenuItem} 
                        onClick={handleItemContextClick}
                        data={ TableEditorActionType.RowAddBefore } > 
                            Add row before 

                    </Item>
                    <Item 
                        disabled={shouldDisabledMenuItem}
                        onClick={handleItemContextClick}
                        data={ TableEditorActionType.RowAddAfter } > 
                            Add row after 
                    </Item>
                    <Item 
                        disabled={shouldDisabledMenuItem}
                        onClick={handleItemContextClick}
                        data={ TableEditorActionType.RowDelete } > 
                        Delete row 
                    </Item>
                    <Separator />
                    <Item 
                        disabled={shouldDisabledMenuItem}
                        onClick={handleItemContextClick}
                        data={ TableEditorActionType.ColumnAddBefore } > 
                            Add column before 
                    </Item>
                    <Item 
                        disabled={shouldDisabledMenuItem}
                        onClick={handleItemContextClick}
                        data={ TableEditorActionType.ColumnAddAfter } > 
                        Add column after
                    </Item>
                    <Item 
                        disabled={shouldDisabledMenuItem}
                        onClick={handleItemContextClick}
                        data={ TableEditorActionType.ColumnDelete } > 
                        Delete column
                    </Item>
                </Menu>
            </>
        }
    </>;
}

// ------------------------------------------------
// A header row
// ------------------------------------------------
const TableEditorHeaderComponent: React.FunctionComponent<TableEditorRowComponentProps> = (props: TableEditorRowComponentProps) => {

    // Variables
    const { data, onEvent, row } = props;

    // Render
    return <>
        <tr>
            {data.map((value: string, index: number) => <th key={`hr${row}_c${index}`}> <TableEditorCellComponent value={value} cell={{ row: row, column: index }} onEvent={onEvent} /> </th>)}
        </tr>
    </>

}


// ------------------------------------------------
// A simple row
// ------------------------------------------------
const TableEditorRowComponent: React.FunctionComponent<TableEditorRowComponentProps> = (props: TableEditorRowComponentProps) => {

    // Variables
    const { data, row, onEvent } = props;

    // Render
    return <>
        <tr>
            {data.map((value: string, index: number) => <td key={`lr${row}_c${index}`} > <TableEditorCellComponent value={value} cell={{ row: row, column: index }} onEvent={onEvent} /></td>)}
        </tr>
    </>

}


// ------------------------------------------------
// Cell of the table
// ------------------------------------------------
const TableEditorCellComponent: React.FunctionComponent<TableEditorCellComponentProps> = ({ value, cell, onEvent }) => {

    // State
    const [edition, setEdition] = React.useState(false);

    // Ref to the input field
    const inputRef = React.useRef<HTMLInputElement | null>(null)

    const raiseEvent = React.useCallback(
        (e: React.SyntheticEvent | TriggerEvent, type: TableEditorEventType, data: string = "") => {
            e.preventDefault();
            onEvent(
                {
                    type: type,
                    cell: cell,
                    data: data,
                    e: e
                }
            );
        }, [cell, onEvent]);

    // Handle one click : to select cell
    const handleOneClick = (e: React.SyntheticEvent) => {
        raiseEvent(e, TableEditorEventType.CellSelect);
    }

    // Handle context menu
    const handleContextMenu = (e: TriggerEvent) => {
        raiseEvent(e,  TableEditorEventType.CellContextMenu);
    }



    // Handle double click : change state to edit value
    const handleDoubleClick = (e: React.SyntheticEvent) => {
        setEdition(true);
        raiseEvent(e, TableEditorEventType.CellEdit);
    }

    // Handle Valid Click
    const handleAddValidClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (inputRef.current) {
            const v = inputRef.current.value ? inputRef.current.value : "";
            raiseEvent(e, TableEditorEventType.CellChange, v);
            setEdition(false);
        }
    }

    return <>
        { edition
            ? <div className="input-group" >
                <InputTextWithButtonComponent
                    data-test-id="txtEditCellValue"
                    defaultValue={value}
                    onValidClick={handleAddValidClick} inputRef={inputRef} > Ok </InputTextWithButtonComponent>
            </div>
            :
            <div onClick={handleOneClick} onContextMenu={handleContextMenu} onDoubleClick={handleDoubleClick} >
                {value ? value : <span> <br /> </span>}
            </div>
        }
    </>

}

