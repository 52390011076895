// import * as actions from "../action-type";
import { Dispatch } from "react";
import { Constant } from "../../constants";
import { HasChildren, Report, ReportChild } from "../../models/report.model";
import { findByKey } from "../../services/report.service";
// import { patchOneContent, PatchOneContentAction } from "./report-content-patch-one.action";
import { setError } from "../common.action";
import { updateAndSaveChild } from "./report-content-update-save-one.action";


/**
 * Update only one child
 * @param report 
 * @param child 
 * @returns 
 */
 export const updateOneChild = (
    report: Report, 
    child: ReportChild
    ) => {
return async (dispatch: Dispatch<any>) => {
    // Where ?
    if (report.content) {

        // Find the parent ?
        let p = report.content as HasChildren;
        if (child.parentKey !== Constant.ROOT_PARENT_KEY) {
            p = findByKey(child.parentKey, report.content!.children) as HasChildren;
        }

        // If p
        if (p) {
            p.children = p.children.map(c => c.key === child.key ? child : c)
        }

        // Update
        updateAndSaveChild(
            report, 
            child,
            "update",
            dispatch
            );

    } else {
        dispatch(setError('Report has no content'));
    }

}
}