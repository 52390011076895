import * as React from "react";
import marked from 'marked';
import sanitizeHtml from 'sanitize-html';

import "./preview.component.css";

interface Props {
    text: string
}

export const PreviewComponent:React.FunctionComponent<Props> = ({ text }) => {

    let markdown = sanitizeHtml(marked(text), {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ])
    }).replaceAll('<table>', '<table class="table table-bordered" >');

    return <>
        <p dangerouslySetInnerHTML={{__html: markdown }}  />
    </>

}