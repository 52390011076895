import * as lodash from "lodash";

export class TableEditor {

    private _internal: string[][] = [];

    constructor(init: string[][] = []) {
        this._internal = lodash.cloneDeep(init);
    }

    /**
     * Does table has row
     * @returns boolean
     */
    hasHeader(): boolean {
        return this._internal.length > 1;
    }

    /**
     * Get the first row
     */
    header(): string[] | null {
        if (this.hasHeader()) {
            return this._internal[0]
        }
        return null;
    }

    /**
     * Must have at least to rows to have rows :)
     * @returns boolean
     */
    hasRows(): boolean {
        return this._internal.length > 2;
    }

    /**
     * Return the number of rows
     * @returns number
     */
    length(): number {
        if (this.hasRows()) {
            return this._internal.length - 2;
        }
        return 0;
    }

    /**
     * Get the rows
     * @returns string[][]
     */
    rows(): string[][] | null {
        if (this.hasRows()) {
            return this._internal.slice(2, this._internal.length);
        }
        return null;
    }

    row(index: number): string[] | null {
        if (this.hasRows() && this.length() > index) {
            return this._internal[index + 2];
        }
        return null;
    }

    /**
     * Add a row before the index
     * @param index 
     */
    addRowBefore(index: number): TableEditor {
        // Index must compatible with the table
        if ((index >= 0) && (index < this.length())) {
            return this.addRow(index + 2);
        }
        // No error ? ...
        return this;
    }

    /**
     * Add a row before the index
     * @param index 
     */
    addRowAfter(index: number): TableEditor {
        // Index must compatible with the table
        if ((index >= 0) && (index < this.length())) {
            return this.addRow(index + 2 + 1);
        }
        // No error ? ...
        return this;
    }

    /**
     * 
     * @param realIndex The real index in the internal table
     */
    private addRow(realIndex: number): TableEditor {
        // Clone
        const tempInternal = lodash.cloneDeep(this._internal);

        // Action
        tempInternal.splice(
            realIndex,
            0,
            new Array(this.header()!.length).fill('')
        );

        // New
        return new TableEditor(tempInternal);
    }

    /**
     * 
     * @param index 
     */
    removeRow(index: number): TableEditor {

        // Clone
        const tempInternal = lodash.cloneDeep(this._internal);

        // Index must compatible with the table
        if ((index >= 0) && (index < this.length())) {
            tempInternal.splice(
                index + 2,
                1
            );
        }

        // New
        return new TableEditor(tempInternal);
    }

    /**
     * 
     * @param index 
     */
    addColumnBefore(index: number): TableEditor {
        if (index >= 0 && this.hasHeader() && index < this.header()!.length) {
            return this.addColumn(index);
        }
        return this;
    }

    addColumnAfter(index: number): TableEditor {
        if (index >= 0 && this.hasHeader() && index < this.header()!.length) {
            return this.addColumn(index + 1);
        }
        return this;
    }

    /**
     * Add a column to each row
     * @param index 
     */
    private addColumn(index: number): TableEditor {

        // Clone
        const tempInternal = lodash.cloneDeep(this._internal);

        for (let i = 0; i < tempInternal.length; i++) {
            const row = tempInternal[i];
            row.splice(
                index,
                0,
                row[0] === '----' ? row[0] : ""
            );
        }

        return new TableEditor(tempInternal);

    }

    /**
     * Remove column
     * @param index 
     */
    public removeColumn(index: number): TableEditor {
        if (
            index >= 0
            && this.hasHeader()
            && this.header()!.length > 1
            && this.header()!.length > index
        ) {
            // Clone
            const tempInternal = lodash.cloneDeep(this._internal);

            for (let i = 0; i < tempInternal.length; i++) {
                const row = tempInternal[i];
                row.splice(
                    index,
                    1
                );
            }

            return new TableEditor(tempInternal);
        }
        return this;
    }

    /**
     * 
     * @param index 
     * @param value 
     */
    public updateHeader(indexColumn: number, value: string): TableEditor {
        return this.update(0, indexColumn, value);
    }


    public updateRow(indexRow: number, indexColumn: number, value: string): TableEditor {
        if (indexRow >= 0) {
            return this.update(indexRow + 2, indexColumn, value);
        }
        return this;
    }

    /**
     * 
     * @param indexRow 
     * @param indexColumn 
     * @param value 
     * @returns 
     */
    public update(indexRow: number, indexColumn: number, value: string): TableEditor {
        // Seriously
        if (!this.hasHeader) { return this; }
        if (indexRow < 0 || indexRow >= this._internal.length) { return this; }
        if (indexColumn < 0 || indexColumn >= this.header()!.length) { return this; }

        // Clone
        const tempInternal = lodash.cloneDeep(this._internal);

        // Normaly is good
        tempInternal[indexRow][indexColumn] = value;

        // Clone
        return new TableEditor(
            tempInternal
        );
    }

}