import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { ErrorAction } from '../models/error.model';
import { request } from '../services/http.service';
import { Actions } from "./action-type";

export const setError = (error: any): ErrorAction => {
    let errorMsg = '';
    if (typeof error === 'string') {
        errorMsg = error;
    } else if (typeof error === 'object' && error.message) {
        errorMsg = error.message;
    } else {
        errorMsg = 'Erreur inconnue';
        console.error(error);
    }
    return {
        type: Actions.SET_ERROR,
        message: errorMsg
    } as ErrorAction;
}

export const clearError = (): ErrorAction => {
    return {
        type: Actions.SET_ERROR,
        message: ''
    } as ErrorAction;
}

export const setFrom = (from: string): ErrorAction => {
    return {
        type: Actions.SET_FROM,
        message: from
    }
}

export const ping = () => {

    return async (dispatch: Dispatch<AnyAction>) => {

        try {
            await request(
                {
                    method: 'GET',
                    url: `/v1/common/ping`,
                }
            );
        } catch (error) {
            dispatch(setError(error));
        }

    }
}