import * as React from "react";

interface Props {
}

export const FooterComponent: React.FunctionComponent<Props> =  React.memo((props) => {

    // Props
    const { children, ...others } = props;

    return <>
            <div {...others} style={ { position:'absolute', left:0, right:0, bottom:0 } } >
                { children }
            </div>
    </>;

})