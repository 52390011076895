import * as React from "react"
import { Dispatch } from "redux"
import { useDispatch } from "react-redux"
import { login } from "../../actions/authentification.action";
import { RegisterComponent } from "../users/register.component";
import { LoginComponent } from "../users/login.component";
import { RowColumnComponent } from "../ui/container/row-colums.component";
import { RouteComponentProps } from 'react-router';


export const LoginPage: React.FC<RouteComponentProps> = ({history}: RouteComponentProps) => {
    
    const dispatch: Dispatch<any> = useDispatch();

    const handleLogin = (username: string, password: string) => {
        dispatch(login(username, password, history));
    }

    return (
        <div className="container" >
            <RowColumnComponent >
                <LoginComponent login={handleLogin} />
                <RegisterComponent />
            </RowColumnComponent>
        </div>
    )
}