import * as React from "react";
import { useSelector } from 'react-redux';
import { Report } from "../../models/report.model";
// import { Constant } from '../../constants';
import { RootState } from '../../models/root-state.model';
// import { Report } from "../../models/report.model";
import { DropDownButtonComponent, LinkButtonComponent } from "../ui/button";
import { IconClipBoardComponent, IconComponent, IconSearchComponent } from "../ui/icon";
import { PasteTextButtonComponent } from "../ui/tools/paste-text-button.component";
import { ReportExportButtonComponent } from './report-export-button.component';

interface Props {
    report: Report,
    username: string,
    title: string,
    onSave: () => void,
    onSnapShot : () => void,
    onStartMeeting : () => void,
    onStopMeeting : () => void,
    onEditor: (id: number) => void,
    onSearch?: () => void
}

const ReportPageHeaderComponentBase: React.FunctionComponent<Props> = ({
        report,
        username,
        title,
        onSave,
        onStartMeeting,
        onStopMeeting,
        onSnapShot,
        onEditor,
        onSearch
}) => {

    const meetingId: string =useSelector((store: RootState) => {
        if(store.meeting.id) { return store.meeting.id; }
        return '';
    });

    const handleActionClickItem = React.useCallback((item) => {
        onEditor(item.code as number);
    }, [onEditor]);

    return <>
        <h1>
            <div className="float-right" >
                {/* <!--<LinkButtonComponent onClick={ () => onSave() }  className="mr-1"> Save </LinkButtonComponent> --> */}
                { onSearch && <LinkButtonComponent onClick={ () => onSearch() }  className="mr-1" > <IconSearchComponent /> </LinkButtonComponent> }
                <LinkButtonComponent onClick={ () => onSnapShot() }  className="mr-1" data-test-id="lnkSnapshots" > Snapshots </LinkButtonComponent>
                { ! meetingId && <LinkButtonComponent onClick={ () => onStartMeeting() } className="mr-1" > Start Meeting </LinkButtonComponent> }
                { meetingId && <LinkButtonComponent onClick={ () => onStopMeeting() } className="mr-1" > Stop Meeting </LinkButtonComponent> }
                <ReportExportButtonComponent report={report} username={ username } > <IconComponent name="file-earmark-arrow-down" /> </ReportExportButtonComponent>
                <DropDownButtonComponent 
                                    style={{ display:'inline'  }}
                                    id='btnEditor'
                                    color='link'
                                    onClickItem={ (item) => handleActionClickItem(item) }
                                    items={[
                                        { code: 2, label: 'Markdown - UIW' },
                                        { code: 0, label: 'Markdown' },
                                        { code: 1, label: 'Textarea'}
                                    ]}
                                    > 
                                        <IconComponent name="display" /> 
                </DropDownButtonComponent>
            </div>
            { title }

        </h1>
        <p className="text-right">
            { 
                meetingId 
                    && <>
                        Share this link : {window.location.origin}/meeting/{meetingId} &nbsp;
                        <PasteTextButtonComponent text={`${window.location.origin}/meeting/${meetingId}` } >
                            <IconClipBoardComponent />
                        </PasteTextButtonComponent>
                        </>
            }
                
        </p>
    </>
}

export const ReportPageHeaderComponent = React.memo(ReportPageHeaderComponentBase);
