import * as React from "react";
import moment from "moment";

import { findByKey } from "../../services/report.service";

import { ReportChild, ReportContentHistory } from "../../models/report.model";
import { SecondaryButtonComponent } from '../ui/button';
import { PreviewComponent } from '../common/preview.component';
import { Constant } from '../../constants';
import { getSlug } from "../../services/string.service";

interface Props {
    selectedChildKey: string,
    snapshots: ReportContentHistory[]

}

const ReportChapterHistoryBase: React.FunctionComponent<Props> = (props: Props) => {

    // Props
    const { selectedChildKey, snapshots } = props;

    // States
    const [historyChild, setHistoryChild] = React.useState<ReportChild[]>([]);

    // Make calculation
    React.useEffect(() => {
        // Init
        const temp: ReportChild[] = [];
        if (selectedChildKey) {
            // Search
            snapshots.forEach(
                s => {
                    const c = findByKey(selectedChildKey, s.children);
                    if (c) {
                        // Handle a switch of the title
                        const c1 = { ...c, title: s.title}
                        temp.push(c1);
                    }
                }
            );
        }
        temp.sort((a, b) => b.timespan - a.timespan)
        setHistoryChild(temp);
    }, [selectedChildKey, snapshots])

    return <>
        {
            historyChild && historyChild.length > 0 ? historyChild.map(
                (s, i, list) => <>
                    { s.text 
                        && s.text.length > 0 
                        && (i === 0 || s.text !== list[i-1].text)
                        && <div key={`button${i}`} className={`button${i}`}>
                        <div className="row"  >
                            <div className="col" >
                                <SecondaryButtonComponent data-toggle="collapse" data-target={`#h${i}`} style={{ width: '100%' }} >
                                    {s.title ? s.title : (s.date ? s.date : moment(s.timespan).format(Constant.FORMAT_DATE)) }
                                </SecondaryButtonComponent>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" >
                                <div 
                                    className={ i === 0 ? "collapse show" : "collapse" } 
                                    id={`h${i}`}
                                    data-test-id={`history-preview-${getSlug(s.title)}`}
                                    >
                                    <PreviewComponent text={s.text} />
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </>
            ) : <div key="lala"></div>

        }
    </>
}

export const ReportChapterHistory = React.memo(ReportChapterHistoryBase);