import * as React from "react"
import { RowColumnComponent } from '../ui/container/row-colums.component'

export const RegisterComponent: React.FC<{}> = () => {

    return (
        <>
            <RowColumnComponent key="title" className="text-center">
                <h4>Register</h4>
            </RowColumnComponent>
            <p className="text-center">
                Coming soon !
            </p>
        </>
    )
}