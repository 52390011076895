// import * as React from "react";
import { Actions } from "../../actions/action-type";
import { ResourceAction, ResourceState } from "../../models/resource.model";

const initialState: ResourceState = {
    resources: {}
}

export const ResourceReducer = (
    state: ResourceState = initialState,
    action: ResourceAction
): ResourceState => {

    switch (action.type) {
        case Actions.RESOURCE_GET_LIST:
            // Update the list
            const listGet = { ...state.resources };
            listGet[action.payload.report.id] = action.payload.list!;
            // Update the test
            return { ...state, resources: listGet };

        case Actions.RESOURCE_UPDATE_ONE:
            // Id
            const reportUpdateId = action.payload.report.id;
            const resourceUpdateId = action.payload.resource!.id;

            // Need the list
            if (!state.resources[reportUpdateId]) {
                state.resources[reportUpdateId] = [];
            }

            // Delete if exist
            state.resources[reportUpdateId] = state.resources[reportUpdateId].filter(r => r.id !== resourceUpdateId);

            // Add
            state.resources[reportUpdateId] = [
                ...state.resources[reportUpdateId],
                action.payload.resource!
            ];

            return { ...state };
        case Actions.RESOURCE_DELETE_ONE:

            // Id
            const reportDeleteId = action.payload.report.id;
            const resourceDeleteId = action.payload.resource!.id;

            // If the list
            if (state.resources[reportDeleteId]) {
                // Filter
                state.resources[reportDeleteId] = state.resources[reportDeleteId].filter(r => r.id !== resourceDeleteId);
                // Return
                return { ...state };
            }

            return state;

        default:
            return state;
    }
}