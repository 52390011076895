import * as React from "react";
import { IconComponent } from "./icon.component";

interface Props extends React.HtmlHTMLAttributes<HTMLElement>{
}

export const IconBackComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='arrow-left-square' {...others} />
    </>;
});

export const IconChevronDownComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='chevron-down' {...others} />
    </>;
});

export const IconChevronRightComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='chevron-right' {...others} />
    </>;
});

export const IconDashComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='dash' {...others} />
    </>;
});



export const IconSearchComponent: React.FunctionComponent<Props> = React.memo( (props) => {
    
    // Props
    const { ...others } = props;
    
    return <>
        <IconComponent name='search' {...others} />
    </>;
});

export const IconEyeComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='eye' {...others} />
    </>;
});

export const IconClipBoardComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='clipboard' {...others} />
    </>;
});

export const IconBigPlusComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='plus' style={{'fontSize' : '2em'}} {...others} />
    </>;
});


export const IconPlusComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='plus' {...others} />
    </>;
});

export const IconTrashComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='trash' {...others} />
    </>;
});

export const IconPenComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { ...others } = props;

    return <>
        <IconComponent name='pen' {...others} />
    </>;
});
