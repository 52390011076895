import * as React from "react"
import { useDispatch } from 'react-redux';
import { getAllReport } from '../../actions/report.action';
import { ReportAddButtonComponent } from '../report/report-button-add.component'
import { ReportListComponent } from "../report/report-list.component";



export const ReportsPage: React.FC<{}> = () => {

    const dispatch: React.Dispatch<any> = useDispatch();


    React.useEffect(
        () => {
            dispatch(getAllReport())
        },
        [dispatch]
    )

    return (
        <div className="reports container">
            <h1> 
                <div className="float-right" >
                    <ReportAddButtonComponent />
                </div>
                Reports 
            </h1>
            <ReportListComponent />
            
        </div>
    )
}