import { Report, ReportAction, ReportContentHistory } from "../../models/report.model";
import { AnyAction, Dispatch } from 'redux';
import * as lodash from "lodash";
import * as actions from "../action-type";
import { setError } from '../common.action';
import { request } from "../../services/http.service";

export const deleteSnapshot = (
    report: Report,
    snapshot: ReportContentHistory
) => {
    return async (dispatch: Dispatch<any>) => {

        try {
            // To avoid a big error ...
            if (!report.content) { return; }

            // Clone the report
            const updatedReport = lodash.cloneDeep(report);


            // Delete the history from the clone
            if (updatedReport.content) {
                updatedReport.content.history = updatedReport.content.history.filter(s => s.key !== snapshot.key);
            }

            // Save on network
            dispatch(
                deleteHistory(
                    report,
                    snapshot
                )
            );

            // Dispatch
            dispatch({
                type: actions.PUT_UPDATE_CONTENT,
                reports: [updatedReport]
            } as ReportAction);


        } catch (error) {
            console.error(error);
            if (error && error.message) { dispatch(setError(error.message)); }
        }


    }
}


/**
 * Patch the content to the server. All Data !
 *  * 
 * @param report 
 */
 const deleteHistory = (
    report: Report,
    history: ReportContentHistory
) => {

    return async (dispatch: Dispatch<AnyAction>) => {
        try {

            await request({
                method: 'DELETE',
                url: `/v1/content/${report.id}/history/${history.key}`
            });

        } catch (error) {
            dispatch(setError(error));
        }
    }
}
