import * as React from "react"

interface Props extends React.HTMLAttributes<HTMLDivElement> {
        className?: string,
        colClassName?: string
        children: React.ReactNode | React.ReactNode[]
        colSize?: string[]
}

export const RowColumnComponent: React.FunctionComponent<Props> = props => {

    const { className, colClassName, colSize, ...other } = props;

    // One or multiple element ?
    let child: React.ReactNode;
    if(Array.isArray(props.children)) {
        child = props.children.map( (child, index) => renderCol(child, colClassName, colSize, index) );
    } else {
        child = renderCol(props.children, colClassName)
    }

    return (
        <>
            <div className={"row " + className } {...other } >
               { child }
            </div>
        </>
    )
}

const renderCol = (child: React.ReactNode, className: string = '', colSize: string[] = [], index = 0) => {

    // Search in colSize Array if there is a size for the column
    const getColSize = () => {
        if(index <= colSize.length - 1 && colSize[index]) {
            return `-${colSize[index]}`;
        }
        return '';
    };


    return <div className={`col${getColSize()} ${className}` } key={index} > {child} </div>
}