import React, { Dispatch } from 'react';
import { Route, Switch } from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary'

import { Header } from "./components/common/header.component";
import { HomePage, ProfilePage, ReportPage, ReportsPage, SnapshotPage, LoginPage, SearchPage } from "./components/page";
import { Error } from "./components/common/error.component";
import { requireAuth } from "./components/hoc/requireAuth";
import './App.css';
// import { Dispatch } from 'redux';
// import { useDispatch, useSelector } from 'react-redux';
// import { getAllReport } from './store/actions/report.action';
// import { RootState } from './models/root-state.model';
import { requireReport } from './components/hoc/requireReport';
import { ViewReportPage } from './components/page/view.page';
import { MeetingPage } from "./components/page/meeting.page";
import { useDispatch } from 'react-redux';
import { ping, setFrom } from './actions/common.action';
import { Constant } from './constants';
// import axios from "axios";
import { logout } from './actions/authentification.action';
import { RouteComponentProps, withRouter } from 'react-router';
import { register401Interceptor } from './services/http.service';
import { SeeReportPage } from './components/page/see-report';
import { TablePage } from './components/page/table.page';
import { InputPage } from './components/page/input.page';
// import { duration } from 'moment';

/**
 * <Errors />
 */

function ErrorFallback(error: any) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
        </div>
    )
}


const App: React.FunctionComponent<RouteComponentProps> = ({ history }) => {

    const dispatch: Dispatch<any> = useDispatch();


    // // Gestion des 401
    // axios.interceptors.response.use(function (response: any) {
    //     return response;
    // }, function (error:any) {
    //     if (401 === error.response.status) {
    //         dispatch(logout(history))
    //     } 
    //     return Promise.reject(error);
    // });
    register401Interceptor(
        dispatch,
        logout,
        history
    )

    React.useEffect(() => {
        if (localStorage.getItem(Constant.LOCALSTORAGE_TOKEN)) {
            dispatch(setFrom(history.location.pathname));
            dispatch(ping());
        }
    });


    return (
        <>
            <Header />
            <Error />
            <ErrorBoundary FallbackComponent={ErrorFallback} >
                <main role="main" >
                    <div className="body_content">
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route exact path="/table" component={TablePage} />
                            <Route exact path="/input" component={InputPage} />
                            <Route exact path="/profile" component={requireAuth(ProfilePage)} />
                            <Route exact path="/reports" component={requireAuth(ReportsPage)} />
                            <Route exact path="/report/:id" component={requireAuth(requireReport(ReportPage))} />
                            <Route exact path="/report/:id/snapshots" component={requireAuth(requireReport(SnapshotPage))} />
                            <Route exact path="/report/:id/search" component={requireAuth(requireReport(SearchPage))} />
                            <Route exact path="/see/report/:id" component={ requireAuth(requireReport( SeeReportPage)) } />
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/view/:id" component={requireReport(ViewReportPage)} />
                            <Route exact path="/meeting/:meetingId?" component={MeetingPage} />
                        </Switch>
                    </div>
                </main>
            </ErrorBoundary>
        </>
    );
}

export default withRouter(App);

