import * as React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Report } from '../../models/report.model';
import { RootState } from '../../models/root-state.model';
import { ReportEditButtonComponent } from "../report/report-button-edit.component";
import { LinkButtonComponent } from "../ui/button";
import { IconEyeComponent, IconPenComponent } from "../ui/icon";
import { ReportDeleteButtonComponent } from "./report-button-delete.component";

import { canDelete, canEdit, canRead } from "../../services/access.service";

interface Props {

}

export const ReportListComponent:  React.FunctionComponent<Props> = (props) => {

    const reports:Report[] = useSelector((store:RootState) => store.reports.reports );

    return <>
        <table className="table table-striped table-hover" >
            <thead>
                <tr>
                    <th> Label </th>
                    <th> Description </th>
                    <th> &nbsp; </th>
                </tr>
            </thead>
            <tbody>
                { 
                    reports && reports.map(report => 
                        canRead(report) && 
                            <tr key={report.id} >
                                <td> { report.label } </td>
                                <td> { report.description } </td>
                                <td className="text-right">
                                    <Link to={`/see/report/${report.id}` }> 
                                        <LinkButtonComponent title="See" >
                                            <IconEyeComponent />
                                        </LinkButtonComponent>
                                    </Link>
                                    {
                                        canEdit(report) && 
                                            <Link to={`/report/${report.id}` } data-test-id="btnEditReport" > 
                                                <LinkButtonComponent title="Edit" >
                                                    <IconPenComponent />
                                                </LinkButtonComponent>
                                            </Link>
                                    }
                                    {
                                        canEdit(report) && 
                                            <ReportEditButtonComponent report={report} />

                                    }
                                    {
                                        canDelete(report) &&
                                            <ReportDeleteButtonComponent report={report} />
                                    }
                                </td>
                            </tr>
                    )
                }
            </tbody>
        </table>
    </>


}