import * as React from "react";

interface Props {
    labels: string[],
    children: React.ReactNode[],
    keyList?: string
}

export const SelectorComponent: React.FunctionComponent<Props> =  React.memo((props) => {

    // Props
    const { labels, children, keyList, ...others } = props;

    // Which one is selected ?
    const [selected, setSelected] = React.useState(0);

    // Handle selection
    const handleSelection = React.useCallback((event : React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setSelected( parseInt( event.target.value ));
    }, []);

    return <>
        <select className='form-control' onChange={handleSelection}  value={selected} {...others} >
            { 
                labels.map(
                    (label:string, index:number) => 
                        <option key={index} value={index} > { label } </option>
                )
            }
        </select>
        { 
            children && children.map((child: React.ReactNode, index: number) => {
                return (index === selected) && 
                    <div key={`${keyList}${index}`} className="w-100 p-3"  >
                        { child }
                    </div> 
            })
        }
    </>;

})