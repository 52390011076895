import * as React from "react";
import { InjectedFormProps, reduxForm, Field } from 'redux-form';
import { connect, useSelector } from "react-redux";
import { SubmitButtonComponent } from "../ui/button/button-submit.component";
import { FieldInputComponent } from "../ui/field/field-input.component";
import { RowColumnComponent } from '../ui/container/row-colums.component';
import { Constant } from "../../constants";
import { LinkButtonComponent } from "../ui/button";
import { RootState } from "../../models/root-state.model";

export interface IFormData {
  username:string;
  password:string;
}

export interface IDispatchProps {
    login: (username: string, password:string) => any
}

const LoginComponentBase: React.FunctionComponent<IDispatchProps & InjectedFormProps<IFormData, IDispatchProps>> = props => {

    const from = useSelector((store:RootState) => {
        // return store.reports.reports.find(r => r.id.toString() === id );
        return store.common.fromUrl;
    });
    
    const handleSubmit = (formValues: IFormData) => {
        props.login(formValues.username, formValues.password);
    }

    return <form onSubmit={props.handleSubmit(handleSubmit)} >
        <RowColumnComponent key="title" className="text-center">
            <h4>Connexion</h4>
        </RowColumnComponent>
        <Field
            name="username"
            component= { FieldInputComponent }
            type="text"
            label="Username"
            required={true}
        />
        <Field
            name="password"
            component={FieldInputComponent}
            type="password"
            label="Password"
            required={true}
        />
        <RowColumnComponent key="button" className="text-center form-group mb-5">
            <SubmitButtonComponent disabled={props.invalid} data-test-id="btnLoginConnection" > Connexion </SubmitButtonComponent>
            <a href={`${Constant.BASE_URL()}/lostpassword`} > <LinkButtonComponent > Lost password </LinkButtonComponent></a>
        </RowColumnComponent>
        {
              from 
                && from.includes('meeting')
                && 
                <RowColumnComponent key="meeting" className="text-center form-group">
                  Come for a meeting ?
                  <a href={from} >
                    <LinkButtonComponent> Go to meeting </LinkButtonComponent>
                  </a>
                </RowColumnComponent>
            }
      </form>
};

interface IErrorsForm {
    username?:string;
    password?:string;
  }

const loginValidation = (values:IFormData) => {
    const errors:IErrorsForm = {};
  
    if(!values.password) {
      errors.password = "Please provide password";
    }
  
    if(!values.username) {
      errors.username = "Please provide username";
    }
  
    return errors;
  };

export const LoginComponentForm = reduxForm<IFormData, IDispatchProps>({
  form: 'loginForm',
  validate: loginValidation,
})(LoginComponentBase);

export const LoginComponent = connect()(LoginComponentForm);