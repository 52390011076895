import * as React from "react";
import { ReportChild } from "../../models/report.model";
import { SelectInputComponent } from '../ui/field/select-input.component';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    child: ReportChild;
    readonly?: boolean
    changeChildState?: (state:string) => void,

}

const childState = [
    { code:"waiting", label:"Waiting" },
    { code:"running", label:"Running" },
    { code:"normal", label:"Normal" },
    { code:"done", label:"Done" },
]

const ReportChildHeaderComponentBase: React.FunctionComponent<Props> = props => {

    const { child, changeChildState, readonly= true } = props;

    const handleStateChange = React.useCallback((state: string) => {
        if(changeChildState && !readonly) {
            changeChildState(state);
        }
    }, [changeChildState, readonly]);

    return <div className="ml-2 mr-2 mb-3">
            <div className='float-right form-inline' style={{ display:'inherit' }}> 
                <small> Last modification </small> <strong>{ child.date } </strong> <small>by</small> <strong> { child.user} </strong>. <small>Current state </small> :
                {
                    readonly ? <strong> { child.state } </strong> : 
                        <SelectInputComponent 
                        values={childState}
                        selected={child.state}
                        setSelectedValue={ handleStateChange }
                        data-test-id="selectChildrenState"
                        ></SelectInputComponent>
                }
            </div>
            <h4> { child.title } </h4>
        </div>;


}


export const ReportChildHeaderComponent = React.memo(ReportChildHeaderComponentBase);

