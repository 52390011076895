import * as React from "react";
import { useDispatch } from 'react-redux';

import { Report } from "../../models/report.model";
import { LinkButtonComponent, PrimaryButtonComponent } from '../ui/button';
import { RowColumnComponent } from '../ui/container/row-colums.component';
import { addVar, deleteVar } from "../../actions/report-content.action";
import { IconBigPlusComponent, IconTrashComponent } from "../ui/icon";
import { canManageVar } from "../../services/access.service";

interface Props {
    report: Report
}

export const ReportVarsComponent: React.FunctionComponent<Props> = (props: Props) => {

    const dispatch: React.Dispatch<any> = useDispatch();

    // Explode Props
    const { report } = props;

    // State for the new element
    const [showNewInput, setShowNewInput] = React.useState<boolean>(false);

    // Ref for the new var
    const newVarCodeRef = React.useRef<HTMLInputElement | null>(null)
    const newVarValueRef = React.useRef<HTMLInputElement | null>(null)


    // Handle a click on a new Var valid button
    const onNewValidClick = React.useCallback((e: React.SyntheticEvent) => {
        // 
        e.preventDefault();
        if(newVarCodeRef.current 
            && newVarValueRef.current
            && newVarCodeRef.current.value
            && newVarValueRef.current.value) {
            dispatch(addVar(report, newVarCodeRef.current.value, newVarValueRef.current.value));
            setShowNewInput(false);
        }
    }, [dispatch, report]);


    // Handle a click on delete element
    const onDeleteClick = React.useCallback((code: string) => {
        dispatch(
            deleteVar(report, code)
        )

    }, [dispatch, report]);

    return <>
        {
            report && report.content && report.content.vars.map((l, i) => {
                return <RowColumnComponent className="mb-2" key={ `${l.code}-${i}` }>
                    <span> { l.code } </span>
                    <span> { l.value } </span>
                    { 
                        canManageVar(report) 
                        && <div className="text-right" >
                            <LinkButtonComponent onClick={ () => { onDeleteClick(l.code) } }> <IconTrashComponent /> </LinkButtonComponent>
                        </div>
                    }
                </RowColumnComponent>
            })
        }
        {
            canManageVar(report)
                && <RowColumnComponent>
                <div className="text-right" >
                    <LinkButtonComponent 
                        onClick={ () => { setShowNewInput(! showNewInput ) }} 
                        data-test-id="btnAddVar" 
                            > 
                            <IconBigPlusComponent /> 
                    </LinkButtonComponent>
                </div>
            </RowColumnComponent>
        }
        {
            showNewInput && 
                <div className="input-group mt-3" >
                    <input type="text" className="form-control" placeholder={'CODE'} ref={newVarCodeRef} data-test-id="txtVarCode"  />
                    <input type="text" className="form-control" placeholder={'VALUE'} ref={newVarValueRef} data-test-id="txtVarValue"  />
                    <div className="input-group-append" >
                        <PrimaryButtonComponent id="new-var" onClick={ onNewValidClick } > Ok </PrimaryButtonComponent>
                    </div>
                </div>
        }
    </>
}