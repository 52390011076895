import React from 'react';
import ReactDOM from 'react-dom';

// import 'feather-icons/dist/feather';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'jquery/dist/jquery';
import 'popper.js/dist/popper';
import './index.css';

import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

// import  from "./store/reducers/global.reducer";
import { globalReducer } from "./store/reducers/global.reducer";
import App from './App';
import { setAuthentification } from './actions/authentification.action';


const createStoreWithMiddleware = applyMiddleware(
    thunk,
  )(createStore);


const store = createStoreWithMiddleware(
    globalReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
  );

const token = localStorage.getItem("token");
const username = localStorage.getItem("username");
const userId = localStorage.getItem("userId");
let userIdNumber = -1;
if(userId) {
  userIdNumber =  parseInt(userId);
}

if (token && username) {
    store.dispatch(setAuthentification(true, username, userIdNumber));
}


ReactDOM.render(
  <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
