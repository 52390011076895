import * as actions from "./action-type";
// import { Constant } from "../../constants";
// import axios from "axios";
// import { AxiosError } from "axios";
import { AnyAction, Dispatch } from 'redux';
// import { ErrorServerResponse } from '../../models/error.model';
import { Report, ReportLight, ReportAction } from '../models/report.model';
import { setError } from "./common.action";
import { request } from "../services/http.service";
import { updateReportContent } from "../services/report.service";
import { patchContent } from './report-content.action';
import { getReportResources } from "./resource.action";


export const getAllReport = () => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            const response = await request({
                method: 'GET',
                url: `/v1/content`
            });
            dispatch({
                type: actions.GET_CONTENT_LIST,
                reports: response.data
            })
        } catch (error) {
            dispatch(setError(error));
        }
    }
}

export const putUpdateReport = (
    report: Report,
    successCallBack: any,
    errorCallBack: any
) => {

    return async (dispatch: Dispatch<AnyAction>) => {
        try {
            const response = await request({
                method: 'PUT',
                url: `/v1/content/${report.id}`,
                data: {
                    'label': report.label,
                    'description': report.description
                }
            });

            successCallBack();

            dispatch({
                type: actions.PUT_UPDATE_CONTENT,
                reports: [response.data]
            } as ReportAction);

        } catch (error) {
            errorCallBack(error);
        }
    }

}

export const postNewReport = (
    report: ReportLight,
    successCallBack: any,
    errorCallBack: any
) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {

            const response = await request({
                method: 'POST',
                url: `/v1/content`,
                data: {
                    'label': report.label,
                    'description': report.description
                }
            })
            successCallBack();

            dispatch({
                type: actions.POST_NEW_CONTENT,
                reports: [response.data]
            } as ReportAction);

        } catch (error) {
            errorCallBack(error);
        }
    }
}

export const deleteReport = (
    report: Report
) => {
    return async (dispatch: Dispatch<AnyAction>) => {
        try {

            await request({
                method: 'DELETE',
                url: `/v1/content/${report.id}`,
            });

            dispatch({
                type: actions.DELETE_CONTENT,
                reports: [report]
            } as ReportAction);

        } catch (error) {
            dispatch(setError(error));
        }
    }
}

export const completeReport = (
    report: Report
) => {
    return async (dispatch: Dispatch<any>) => {
        // If content present
        if (report.content) {
            return;
        }

        // Get the report
        dispatch(getReport(report.id));
    }
}

export const getReport = (
    id: number | string
) => {
    return async (dispatch: Dispatch<any>) => {
        // Otherwise
        try {
            const response = await request({
                method: 'GET',
                url: `/v1/content/${id}`,
            })

            // Cast
            const report: Report = response.data as Report;

            if (updateReportContent(report)) {
                dispatch(patchContent(report));
            }

            dispatch({
                type: actions.PUT_UPDATE_CONTENT,
                reports: [report]
            } as ReportAction);

            // Get the resources
            dispatch(getReportResources(report));


        } catch (error) {
            dispatch(setError(error));
        }
    }
}
