import * as React from "react";


interface Props extends React.HTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    checked: boolean;
    setChecked: (value: boolean) => void;
}


export const CheckBoxInputComponent: React.FunctionComponent<Props> = props => {

    // Props
    const {label,name, checked, setChecked, ...others } =  props;

    // Handle change
    const onChange = React.useCallback((event : React.ChangeEvent<HTMLInputElement>) => {
        setChecked( event.target.checked );
    }, [setChecked]);

    return (
        <div className="form-group form-check" >
            <input 
                type="checkbox"
                className="form-check-input"
                checked={checked}
                onChange={onChange}
                {...others}  

            />
            <label className="form-check-label" >{label}</label>
       </div>
      );
}