import axios from "axios";
import { AxiosError } from "axios";
import { AnyAction, Dispatch } from 'redux';

import * as actions from "./action-type";
import { IsLoggedInAction } from "../models/islogged.model";
import { setError } from "./common.action";
import { ErrorServerResponse } from '../models/error.model';
import { Constant } from "../constants";
import { RootState } from '../models/root-state.model';


export function setAuthentification(isLoggedIn: boolean, username: string, userid: number) {
    const action: IsLoggedInAction = {
        type: actions.SET_AUTHENTIFICATION,
        isLoggedIn: isLoggedIn,
        username: username,
        userId: userid
    }
    return action;
}

export const login = (username: string, password: string, history: any) => {
    return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {
        try {
            const response = await axios.post(
                `${Constant.BASE_URL_API}/login_check`,
                {
                    username,
                    password
                }
            );
            dispatch(setAuthentification(true, username, response.data.data.id));
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("username", username);
            localStorage.setItem("userId", response.data.data.id);


            // Redirect
            const from = getState().common.fromUrl || '/reports';
            history.push(from);
        } catch (error) {
            if (error && error.response) {
                const axiosError = error as AxiosError<ErrorServerResponse>
                dispatch(setError(axiosError.response?.data.message));
            } else {
                dispatch(setError(error));
            }
        }
    }
}

export const logout = (history: any) => {
    localStorage.removeItem("token");
    history.push('/login');
    return setAuthentification(false, '', -1);
}

