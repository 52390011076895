import * as React from "react";


interface Props {
    name: string,
    label: string,
    input: any,
    type: string,
    meta: any,
    placeholder?: string
}


export const FieldInputComponent: React.FunctionComponent<Props> = props => {

    return (
        <div className="row container form-group">
            <label className="bmd-label-floating">{props.label}</label>
            <input {...props.input} type={props.type} className="form-control" placeholder={props.placeholder} />
            {props.meta.touched &&
              props.meta.error &&
              <span className="error">{props.meta.error}</span>}
        </div>
      );
}