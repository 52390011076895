import { Constant } from '../constants';

export const SET_AUTHENTIFICATION = 'SET_AUTHENTIFICATION';

export const GET_CONTENT_LIST = 'GET_CONTENT_LIST';
export const GET_CONTENT = 'GET_CONTENT';
export const POST_NEW_CONTENT = 'POST_NEW_CONTENT';
export const PUT_UPDATE_CONTENT = 'PUT_UPDATE_CONTENT';
export const DELETE_CONTENT = 'DELETE_CONTENT';

export class Actions {

    public static readonly SET_ERROR = 'SET_ERROR';
    public static readonly SET_FROM = 'SET_FROM';

    public static readonly MEETING_SET_ID = 'MEETING_SET_ID';
    public static readonly MEETING_END = 'MEETING_END';
    public static readonly MEETING_SELECTED_CHILD_CHANGE = Constant.MEETING_SELECTED_CHILD_CHANGE;
    public static readonly MEETING_TEXT_CHANGED = Constant.MEETING_TEXT_CHANGED;
    public static readonly MEETING_SET_REPORT = Constant.MEETING_SET_REPORT;

    public static readonly RESOURCE_GET_LIST = "RESOURCE_GET_LIST";
    public static readonly RESOURCE_UPDATE_ONE = "RESOURCE_UPDATE_ONE";
    public static readonly RESOURCE_DELETE_ONE = "RESOURCE_DELETE_ONE";


    public static readonly UAC_GET_LIST = "UAC_GET_LIST";
    public static readonly UAC_UPDATE_ONE = "UAC_UPDATE_ONE";
    public static readonly UAC_DELETE_ONE = "UAC_DELETE_ONE";

    public static readonly REPORTEDITING_GET_LIST = "REPORTEDITING_GET_LIST";

}