import * as React from "react";
import Editor from "for-editor-herb";
import Hljs from 'highlight.js';
import MDEditor, { commands } from '@uiw/react-md-editor';
// import MDEditor, { commands, ICommand, TextState, TextApi } from '@uiw/react-md-editor';


export interface EditorProps {
    text: string,
    setText: (text: string) => void,
    editor?: number
}

Hljs.registerLanguage('css', require('highlight.js/lib/languages/css'))
Hljs.registerLanguage('json', require('highlight.js/lib/languages/json'))
Hljs.registerLanguage('less', require('highlight.js/lib/languages/less'))
Hljs.registerLanguage('scss', require('highlight.js/lib/languages/scss'))
Hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'))
Hljs.registerLanguage('typescript', require('highlight.js/lib/languages/typescript'))
Hljs.registerLanguage('go', require('highlight.js/lib/languages/go'))

const customLang: any = {
    placeholder: "Il faut bien commencer...",
    undo: "Annuler",
    redo: "Refaire",
    h1: "En-tête 1",
    h2: "En-tête 2",
    h3: "En-tête 3",
    h4: "En-tête 4",
    h5: "En-tête 5",
    h6: "En-tête 6",
    img: "Lien image",
    para: "Paragraphe",
    italic: "Italic",
    bold: "Gras",
    bolditalic: "Gras Italic",
    delline: "Delete Line",
    underline: "Sousligné",
    keytext: "Keyboard Text",
    superscript: "Superscript",
    subscript: "Subscript",
    marktag: "Mark Tag",
    table: "Table",
    quote: "Quote",
    link: "Link",
    list: "List",
    orderlist: "List ordonné",
    disorderlist: "List sans ordre",
    checklist: "Check List",
    inlinecode: "Inline Code",
    code: "Code",
    collapse: "Collapse",
    katex: "KaTeX",
    save: "Sauver",
    preview: "Preview",
    singleColumn: "Single Column",
    doubleColumn: "Double Columns",
    fullscreenOn: "FullScreen ON",
    fullscreenOff: "FullScreen OFF",
    addImgLink: "Ajouter un lien Image",
    addImg: "Téléverser une image",
    toc: "Générer TOC"
}

const options = {
    h1: false,
    h2: false,
    h3: false,
    h4: false,
    h5: false,
    h6: false,
    img: false,
    list: true,
    para: {
        paragraph: true,            // control the whole part if you don't want to display
        italic: true,
        bold: true,
        bolditalic: true,
        delline: true,
        underline: true,
        keytext: true,
        superscript: true,
        subscript: true,
        marktag: true
    },
    table: true,
    quote: true,
    link: true,
    inlinecode: true,
    code: true,
    collapse: false,
    katex: false,
    preview: false,
    expand: false,
    undo: true,
    redo: true,
    save: false,
    subfield: false,
    toc: false
}

export const EditorComponent: React.FunctionComponent<EditorProps> = ({ text, setText, editor = 0 }) => {


    // // Test
    // if(text.includes(":")) {
    //     text =  text.replaceAll(":","=");
    // }

    const handleChange = (value: string) => {
           setText(value);
    }

    switch (editor) {
        case 0:
            return <>
                     <Editor
                         value={text}
                         onChange={handleChange}
                         highlight={() => { return 'css' }}
                         language={customLang}
                         toolbar={options}
                         height="100%"
                     />
                </>
        case 2:
            return <>
                <MDEditor
                    value={text}
                    onChange={(value?: string) => { 
                         if(value && value !== text) {
                             handleChange(value);
                        } 
                    }}
                    height="100%"
                    preview="edit"
                    visiableDragbar={ false }
                    previewOptions={{
                        transformLinkUri: null,
                        skipHtml: true,
                      }}
                    commands={[
                        commands.bold, commands.italic, commands.strikethrough, commands.hr, commands.divider, commands.quote, commands.code, commands.link, commands.image
                        // Custom Toolbars
                      ]}
                />
            </>
        default:
            return <textarea
                        className="form-control w-100 p-3 h-75"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value)}
                    value={text}
                    />;
    }


}