import * as React from "react";

import { ButtonComponent } from "./button.component";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement > {
   className?:string
}

export const DarkButtonComponent: React.FunctionComponent<Props> = props => {

  return (
      <>
          <ButtonComponent color={'dark'}  {...props } >
              { props.children} 
          </ButtonComponent>
      </>
  )
}

