import * as React from "react";
import { ReactNode } from "react";
import SplitPane from "react-split-pane";

import { LightButtonComponent } from '../button';
import { IconComponent } from "../icon/icon.component";

interface Props {
    leftTitle?: string,
    rightTitle?: string,
    children: {
        leftChild?: ReactNode,
        rightChild?: ReactNode,
        mainContent?: ReactNode,
        headerContent?: ReactNode
    }
}

interface ShowPanel {
    show: boolean,
    defaultSize: number,
    minSize: number;
    maxSize: number;
    size: number;
    oldSize?: number;
}

const defaultLeft: ShowPanel = { show: true, defaultSize: 350, minSize: 250, maxSize: 450, size: 350 };
const defaultRight: ShowPanel = { show: true, defaultSize: 350, minSize: 250, maxSize: 800, size: 350 };
const emptyPanel: ShowPanel = { show: false, defaultSize: 0, minSize: 0, maxSize: 1, size: 0 };


export const ThreeSplitComponent: React.FunctionComponent<Props> = React.memo((props) => {

    // Get element
    const { leftTitle, rightTitle, children } = props;
    const { leftChild, rightChild, mainContent, headerContent } = children;

    // State
    const [left, setLeft] = React.useState<ShowPanel>( defaultLeft);
    const [right, setRight] = React.useState<ShowPanel>( rightChild ? defaultRight : emptyPanel);

    // handleClick
    const handleShowClick = React.useCallback(
        (panel: ShowPanel, defaultData: ShowPanel): ShowPanel => {
            const temp = { ...panel };
            if (panel.show) {
                // Was shown : must be hide
                temp.defaultSize = 50;
                temp.minSize = 50;
                temp.maxSize = 50;
                temp.size = 50;
                temp.oldSize = panel.size;
            } else {
                temp.defaultSize = defaultData.defaultSize;
                temp.minSize = defaultData.minSize;
                temp.maxSize = defaultData.maxSize;
                if (panel.oldSize) { temp.size = panel.oldSize };
            }
            temp.show = !panel.show;
            return temp;
        },
        []
    );

    return <>
        <div>
            {
                headerContent   
            }
        </div>
        <SplitPane split="vertical" primary={'first'}
            defaultSize={left.defaultSize} minSize={left.minSize} maxSize={left.maxSize} size={left.size}
            onDragFinished={(size: number) => setLeft({ ...left, size: size })}
            style={{ height: 'calc(100% - 180px)', overflow: 'auto' }}
        >

            {
                left.show
                    && left ?
                    <>
                        <h4>
                            <LightButtonComponent onClick={() => setLeft(handleShowClick(left, defaultLeft))} className="ml-1 mr-1"> {left.show ? <IconComponent name="arrow-bar-left" /> : <IconComponent name="arrow-bar-right" /> } </LightButtonComponent>
                            {leftTitle ? leftTitle : 'Left Panel'}
                        </h4>

                        <div style={{ height: 'calc(100% - 47px)', overflow: 'auto' }}> {leftChild} </div>
                    </>
                    : <LightButtonComponent onClick={() => setLeft(handleShowClick(left, defaultLeft))} className="ml-1"> {left.show ? <IconComponent name="arrow-bar-left" /> : <IconComponent name="arrow-bar-right" /> } </LightButtonComponent>
            }
            <SplitPane split="vertical" primary={'second'}
                defaultSize={right.defaultSize} minSize={right.minSize} maxSize={right.maxSize} size={right.size}
                onDragFinished={(size: number) => setRight({ ...right, size: size })}
            >
                <div style={{ height: 'calc(100%)', overflow: 'auto' }}>
                    {
                        mainContent ? mainContent : <h1> Waiting for some action ! </h1>
                    }
                </div>
                {
                    rightChild ?
                        <>
                            {
                                right.show && right ?
                                    <>
                                        <h4 className="text-left ml-1" >
                                            {rightTitle ? rightTitle : 'Right Panel'}
                                            <LightButtonComponent onClick={() => setRight(handleShowClick(right, defaultRight))} className="ml-1 mr-1"> {right.show ? <IconComponent name="arrow-bar-right" /> : <IconComponent name="arrow-bar-left" />} </LightButtonComponent>
                                        </h4>
                                        <div style={{ height: 'calc(100% - 35px)', overflow: 'auto' }}>
                                            {
                                                rightChild
                                            }
                                        </div>
                                    </>
                                    : <div><LightButtonComponent onClick={() => setRight(handleShowClick(right, defaultRight))} className="ml-1 mr-1"> {right.show ? <IconComponent name="arrow-bar-right" /> : <IconComponent name="arrow-bar-left" />} </LightButtonComponent></div>
                            }
                        </>
                        : <div></div>
                }
            </SplitPane>
        </SplitPane>

    </>;
});