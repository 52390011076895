import * as React from "react"
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from 'redux';
import { RootState } from '../../models/root-state.model';
import { logout } from '../../actions/authentification.action';
import { RouteComponentProps,  withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { Constant } from "../../constants";



 const HeaderBase: React.FunctionComponent<RouteComponentProps> = ({ history } : RouteComponentProps ) => {

    const dispatch: Dispatch<any> = useDispatch();

    // Get isLoggedIn from store
    const isLoggedIn: boolean = useSelector((store: RootState) => {
        return store.authentification.isLoggedIn
    })

    const username: string = useSelector((store: RootState) => {
        return store.authentification.username;
    })

    // Title of the website
    const title = window.location.origin.includes('crayoo') ? 'Crayoo' 
                    : window.location.origin.includes('emvod') ? 'Emvod' : 'My CR Report';


    const handleLogout = (e: React.SyntheticEvent) => {
        e.preventDefault();
        dispatch(logout(history));
    }

    return (
        <>
            <Helmet>
                <title> { title } </title>
            </Helmet>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <Link className="navbar-brand" to={"/"}> { title } <small> { Constant.APP_VERSION() } </small></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav mr-auto">
                        &nbsp;
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item active">
                            <Link className="nav-link" to={"/"}> Home </Link>
                        </li>
                        { isLoggedIn ?
                            <>
                                <li className="nav-item">
                                        <Link className="nav-link" to={"/reports"}> Reports </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"/profile"}> Profile </Link>
                                    </li>
                            </> : null
                        }
                        <li className="nav-item">
                        { isLoggedIn ?
                                <a className="nav-link" href='/logout' onClick={handleLogout} > Log out [{ username }] </a>
                                : <Link className="nav-link" to={"/login"}> Log in </Link>
                        }
                        </li>
                        
    
                    </ul>
                </div>
            </nav>
        </>
    )

}

export const Header = withRouter(HeaderBase);
