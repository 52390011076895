import * as React from "react";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement > {
   className?:string,
   color: string
}

export const ButtonComponent: React.FunctionComponent<Props> = props => {

  const { className, color, ...other } = props;

  return (
      <>
           <button type="button" className={ `btn btn-${color} btn-raised ${className}` }  {...other} >
            { props.children } 
          </button>
      </>
  )
}

