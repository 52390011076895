// import * as React from "react";
import { Actions } from "../../actions/action-type";
import { UACAction, UACState } from "../../models/uac.model";

const initialState: UACState = {
    uacs: {}
}

export const UACReducer = (
    state: UACState = initialState,
    action: UACAction
): UACState => {

    switch (action.type) {
        case Actions.UAC_GET_LIST:
            // Update the list
            const listGet = { ...state.uacs };
            listGet[action.payload.report.id] = action.payload.list!;
            // Update the test
            return { ...state, uacs: listGet };

        case Actions.UAC_UPDATE_ONE:
            // Id
            const reportUpdateId = action.payload.report.id;
            const uacUpdateId = action.payload.uac!.id;

            // Need the list
            if (!state.uacs[reportUpdateId]) {
                state.uacs[reportUpdateId] = [];
            }

            // Delete if exist
            state.uacs[reportUpdateId] = state.uacs[reportUpdateId].filter(r => r.id !== uacUpdateId);

            // Add
            state.uacs[reportUpdateId] = [
                ...state.uacs[reportUpdateId],
                action.payload.uac!
            ];

            return { ...state };
        case Actions.UAC_DELETE_ONE:

            // Id
            const reportDeleteId = action.payload.report.id;
            const resourceDeleteId = action.payload.uac!.id;

            // If the list
            if (state.uacs[reportDeleteId]) {
                // Filter
                state.uacs[reportDeleteId] = state.uacs[reportDeleteId].filter(r => r.id !== resourceDeleteId);
                // Return
                return { ...state };
            }

            return state;

        default:
            return state;
    }
}