import * as React from "react";

interface Props extends React.HtmlHTMLAttributes<HTMLElement>{
    name: string;
}

export const IconComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { name, className, ...others } = props;

    const internalClassName = `bi bi-${name} ${className}`;

    return <>
        <i className={internalClassName} role="img" aria-label={name} {...others} ></i>
    </>;
});