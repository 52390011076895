import * as React from "react";


interface Props extends React.HTMLProps<HTMLInputElement> {
    name: string;
    label: string;
    value?: string;
    setValue: (value: string) => void;
}


export const TextInputComponent: React.FunctionComponent<Props> = props => {

    // Props
    const {label,name, placeholder, value, setValue, ...others } =  props;

    console.log(others);

    // Handle change
    const onChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        // event.preventDefault();
        setValue( event.target.value );
    }; 

    return (
        <div className="form-group">
            <label className="bmd-label-floating">{label}</label>
            <input 
                type="text" 
                className="form-control" 
                placeholder={placeholder} 
                value={value}
                onChange={onChange}
                {...others}  
            />
        </div>
      );
}