import { Dispatch } from "react";
import { AnyAction } from "redux";
import { Constant } from "../../constants";
import { HasChildren, Report, ReportChild } from "../../models/report.model";
import { findByKey } from "../../services/report.service";
import { updateAndSaveChild } from "./report-content-update-save-one.action";

/**
 * Change child order based on direction (simple way)
 * @param report 
 * @param child
 */
 export const changeOneChildOrder = (report: Report, child: ReportChild, direction: string) => {

    return async (dispatch: Dispatch<AnyAction>) => {

        // First we need the parent children collection
        let p = report.content as HasChildren;
        if (child.parentKey !== Constant.ROOT_PARENT_KEY) {
            p = findByKey(child.parentKey, report.content!.children) as HasChildren;
        }
        const children = p.children;
        
        // Index of child in the list
        const index = children.findIndex(c => c.key === child.key);

        // Index of the child to change order
        // /!\ : Direction are based on user action which is the reverse of
        // order !
        let indexChange = index + 1;
        if(direction === Constant.DIRECTION_UP) {
            indexChange = index - 1;
        }

        // Check
        if((indexChange < 0) || (indexChange > children.length -1)) {
            return;
        }

        // Get the child
        const childChange = children[indexChange];

        // Change order
        const order = child.order;
        child.order = childChange.order;
        childChange.order = order;
        
        // Special case
        if(child.order === childChange.order) {
            if(direction === Constant.DIRECTION_UP) {
                childChange.order++;
            } else {
                childChange.order--;
            }
        }

        // console.log("Origin", child.title, child.order);
        // console.log("Change", childChange.title, childChange.order);

        // Set
        p.children = [...children];

        // Save and update
        // -- The child
        updateAndSaveChild(
            report,
            child,
            "update",
            dispatch
        );
        // -- The child change
        updateAndSaveChild(
            report,
            childChange,
            "update",
            dispatch
        );
    }

} // /changeChildOrder