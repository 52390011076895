import axios, { AxiosRequestConfig } from "axios";
import { AxiosError } from "axios";
import { Dispatch } from 'react';
import { Constant } from "../constants";
import { ErrorServerResponse } from '../models/error.model';

export interface HttpConfig {
    method: 'POST'  | 'PATCH' | 'PUT' | 'GET' | 'DELETE',
    url: string,
    data?: any,
    responseType?: 'json' | 'blob',
    headers?: any,
    onUploadProgress?: (progressEvent: ProgressEvent) => void,
    onDownloadProgress?: (progressEvent: ProgressEvent) => void
}

export const request = async (
        config:HttpConfig
) => {
    try {
        // Headers
        const headers = { 
            Authorization: `Bearer ${localStorage.getItem(Constant.LOCALSTORAGE_TOKEN)}`,
            ...config.headers 
        };

        // Config
        const axiosConfig: AxiosRequestConfig = {
            method: config.method,
            url: `${Constant.BASE_URL_API}${config.url}`, // export/xlsx
            headers: headers
        }
        axiosConfig.data = config.data ? config.data : undefined;
        axiosConfig.responseType = config.responseType ? config.responseType : undefined;
        axiosConfig.onUploadProgress = config.onUploadProgress ? config.onUploadProgress : undefined;
        axiosConfig.onDownloadProgress = config.onDownloadProgress ? config.onDownloadProgress : undefined;

        // Request
        const response = await axios.request(axiosConfig);

        // Response
        return response;

    } catch (error) {
        let message: string | undefined = '';
        if (error && error.response) {
            const axiosError = error as AxiosError<ErrorServerResponse>
            message = axiosError.response?.data.message;
        } else {
            message = "Erreur inconnue";
        }
       throw new Error(message);
    }


}


export const register401Interceptor = (dispatch: Dispatch<any>, logout: (arg0: any) => void, history:any)  => {

    // Gestion des 401
    axios.interceptors.response.use(function (response: any) {
        return response;
    }, function (error:any) {
        if (401 === error.response.status) {
            dispatch(logout(history))
        } 
        return Promise.reject(error);
    });
}

