import * as React from "react";
import { pasteText } from "../../../services/paste.service";

interface Props {
    text: string;
    children: React.ReactNode;
}

export const PasteTextButtonComponent: React.FunctionComponent<Props> = React.memo( (props) => {

    // Props
    const { text, children } = props;

    // State
    const [ isCopied, setIsCopied ] = React.useState(false);

    // Handle Click
    const handleClick = React.useCallback(() => {
        pasteText(text);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
   }, [text]);

    return <>
        {
            isCopied ?
                <span> Copied !</span>
                : <span onClick={handleClick}  >
                    { children }
                </span>
        }
    </>;
});