import * as React from "react";
import { Constant } from "../../constants";
import { HasChildren, ReportChild } from "../../models/report.model";
import { LinkButtonComponent, DarkButtonComponent, LightButtonComponent } from '../ui/button';
import { InputTextWithButtonComponent } from "../ui/container";
import { IconBigPlusComponent, IconChevronDownComponent, IconChevronRightComponent, IconComponent, IconDashComponent, IconPenComponent, IconPlusComponent, IconTrashComponent } from "../ui/icon";
import $ from 'jquery'; 



interface Props {
    parent: HasChildren,
    onCreate: (name: string, parent?: ReportChild) => void,
    onUpdate: (child: ReportChild) => void,
    onDelete: (parent: HasChildren, child: ReportChild) => void,
    onChangeOrder: (child: ReportChild, direction: string) => void,
    selectedChild?: ReportChild,
    onSelectChild: (child: ReportChild) => void,
    allowWrite?: boolean
}

export const ReportChaptersComponent: React.FunctionComponent<Props> = React.memo((props: Props) => {

    // Props
    const {
        parent,
        onCreate,
        onUpdate,
        onDelete,
        onChangeOrder,
        selectedChild,
        onSelectChild,
        allowWrite = false
    } = props;

    // State to handle showing new input
    const [showInput, setShowInput] = React.useState<boolean>(false);
    // Get the input ref to ge value
    const myRef = React.useRef<HTMLInputElement | null>(null)

    // handle click on the valid button
    const handleAddValidClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (myRef.current && myRef.current.value) {
            onCreate(myRef.current?.value);
            setShowInput(false);
        }
    }

    const handleNewChild = (parent: ReportChild) => {
        onCreate('New', parent);
    }

    // Handle select item
    const handleSelectChild = (child: ReportChild) => {
        onSelectChild(child);
    }

    return <>
        <div className="p-4" >
            <div className="list-group list-group-root well">
                {parent && parent.children && parent.children.length > 0 && parent.children.sort((a, b) => a.order - b.order).map(
                    (child, index) => <ChapterItemComponent
                        key={child.key}
                        parent={parent}
                        child={child}
                        selectedKey={selectedChild?.key}
                        handleSelectChild={handleSelectChild}
                        onCreate={handleNewChild}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        allowWrite={allowWrite}
                        onChangeOrder={onChangeOrder}
                        index={index}
                        indexMax={parent.children.length - 1}
                        depth={0}
                    />
                )
                }
            </div>
            {
                showInput &&
                <InputTextWithButtonComponent 
                    data-test-id="txtAddChapter"
                    onValidClick={handleAddValidClick} inputRef={myRef} > Ok </InputTextWithButtonComponent>
            }
            <h4 className="pt-3 pb-4">
                <div className="float-right" >
                    {allowWrite && <LinkButtonComponent onClick={() => { setShowInput(!showInput) }} data-test-id="btnAddChapter" > <IconBigPlusComponent /> </LinkButtonComponent>}
                </div>
            </h4>
        </div>
    </>
}
)

const ChapterItemState = {
    view: 'view',
    add: 'add',
    update: 'update',
    delete: 'delete',
    action: 'action',
    up: Constant.DIRECTION_UP,
    down: Constant.DIRECTION_DOWN,
    close: 'close'
}

interface ItemProps {
    parent: HasChildren,
    child: ReportChild,
    selectedKey?: string,
    handleSelectChild: (child: ReportChild) => void,
    onCreate: (parent: ReportChild) => void,
    onUpdate: (child: ReportChild) => void,
    onDelete: (parent: HasChildren, child: ReportChild) => void,
    onChangeOrder: (child: ReportChild, direction: string) => void,
    allowWrite: boolean,
    index: number,
    indexMax: number,
    depth: number
}

const ChapterItemComponent: React.FunctionComponent<ItemProps> = (props: ItemProps) => {

    // Props
    const {
        parent,
        child,
        selectedKey,
        handleSelectChild,
        onCreate,
        onUpdate,
        onDelete,
        onChangeOrder,
        allowWrite,
        index,
        indexMax,
        depth
    } = props;

    // State
    const [state, setState] = React.useState(ChapterItemState.view);
    const [timeout, setTO] = React.useState<any>();
    const [showChildren, setShowChildren] = React.useState(true);

    // Vars
    const isSelected = child.key === selectedKey;
    const getActiveClass = () => {
        if (state === ChapterItemState.delete) return 'delete';
        else if (state === ChapterItemState.action && isSelected) return 'action';
        else if (isSelected) return 'active';
        return '';
    }
    let activeClass = getActiveClass();
    const myRef = React.useRef<HTMLInputElement | null>(null);

    // Validation
    if (!isSelected && state !== ChapterItemState.view) {
        setState(ChapterItemState.view);
    }

    const handleActionClick = React.useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        setState(ChapterItemState.action);
    }, [setState]);

    // Manage a click an item
    const handleActionClickItem = (item: string, child: ReportChild) => {
        switch (item) {
            case ChapterItemState.add:
                onCreate(child);
                setState(ChapterItemState.view);
                break;
            case ChapterItemState.up:
            case ChapterItemState.down:
                //
                onChangeOrder(child, item);
                break;
            case ChapterItemState.close:
                setState(ChapterItemState.view);
                break;
            default:
                setState(item);
                if (item === ChapterItemState.delete) {
                    const t = setTimeout(() => {
                        setState(ChapterItemState.view);
                    }, 3000);
                    setTO(t);
                }
                break;
        }
    }

    // Handle Modification click
    const handleUpdateClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (myRef.current) {
            child.title = myRef.current.value;
            onUpdate(child);
            setState(ChapterItemState.view);
        }
    }

    // Delete an element
    const handleDeleteClick = (e: React.SyntheticEvent, confirm: boolean) => {
        clearTimeout(timeout);
        if (confirm) {
            onDelete(parent, child);
            return;
        }
        setState(ChapterItemState.view);

    }

    const getTitleStyle = (child: ReportChild) => {
        return {
            fontWeight: child.state === 'running' ? 'bold' : 'normal',
            fontStyle: child.state === 'waiting' ? 'italic' : 'normal',
            textDecorationLine: child.state === 'done' ? 'line-through' : 'none'
        } as React.CSSProperties;
    };

    const handleShowChildren = React.useCallback(() => {
        ($(`#t${child.key}`) as any).collapse('toggle');
        setShowChildren(!showChildren);
    }, [showChildren, child]);



    return <>
        <div className={`list-group-item ${activeClass} `}
            key={child.key}
            onClick={(e: React.SyntheticEvent) => { e.preventDefault(); handleSelectChild(child); }}

        >
            { /* View Mode */}
            {
                state === ChapterItemState.view &&
                <div style={ { paddingLeft: `${ depth * 20 }px`, width:'100%' }}>

                    {
                        allowWrite
                        && isSelected
                        && <>
                            <LinkButtonComponent
                            onClick={handleActionClick}
                            style={{ paddingTop:'0px', paddingBottom:'0px', float:'right'}}
                            >
                                <IconComponent name="three-dots-vertical" />
                            </LinkButtonComponent>
                        </>
                    }
                    {child.children && child.children.length > 0 ?
                        <LinkButtonComponent className={isSelected ? 'selectedLinkChevron' : 'linkChevron'}  onClick={() => handleShowChildren()}> 
                            { showChildren ? <IconChevronDownComponent /> : <IconChevronRightComponent/> }
                    </LinkButtonComponent>
                        : <IconDashComponent className="linkChevron" />
                    }
                    <span style={getTitleStyle(child)}> {child.title} </span>
                </div>
            }
            { /* View and selected : show a button to action */}
            {
                allowWrite
                && isSelected
                && state === ChapterItemState.action
                && <>
                    { depth < 1 && <LinkButtonComponent onClick={() => { handleActionClickItem(ChapterItemState.add, child) }} className="mr-1 btn-sm">
                        <IconPlusComponent />
                    </LinkButtonComponent> }
                    <LinkButtonComponent onClick={() => { handleActionClickItem(ChapterItemState.update, child) }} className="mr-1 btn-sm" >
                        <IconPenComponent />
                    </LinkButtonComponent>
                    <LinkButtonComponent onClick={() => { handleActionClickItem(ChapterItemState.delete, child) }} className="mr-1 btn-sm" >
                        <IconTrashComponent />
                    </LinkButtonComponent>
                    {index > 0 && <LinkButtonComponent onClick={() => { handleActionClickItem(ChapterItemState.up, child) }} className="mr-1 btn-sm" >
                        <IconComponent name="arrow-up-circle" />
                    </LinkButtonComponent>}
                    {index < indexMax && <LinkButtonComponent onClick={() => { handleActionClickItem(ChapterItemState.down, child) }} className="mr-1 btn-sm" >
                        <IconComponent name="arrow-down-circle" />
                    </LinkButtonComponent>}
                    <LinkButtonComponent onClick={() => { handleActionClickItem(ChapterItemState.close, child) }} className="mr-1 btn-sm" >
                        <IconComponent name="x-circle" />
                    </LinkButtonComponent>
                </>
            }
            {
                state === ChapterItemState.update 
                    && <InputTextWithButtonComponent 
                        onValidClick={handleUpdateClick} 
                        inputRef={myRef} 
                        defaultValue={child.title}
                        data-test-id="txtEditChapter"
                        > 
                        Ok 
                    </InputTextWithButtonComponent>
            }
            {state === ChapterItemState.delete
                && <span>
                    Confirm ? &nbsp; &nbsp;
                            <DarkButtonComponent onClick={(e) => handleDeleteClick(e, true)}> Yes </DarkButtonComponent>
                            &nbsp;
                            <LightButtonComponent onClick={(e) => handleDeleteClick(e, false)}  > No </LightButtonComponent>
                </span>
            }
        </div>
        {
            child.children
            && child.children.length > 0
            && <div className="list-group collapse show" id={`t${child.key}`} >
                {child.children.sort((a, b) => a.order - b.order).map(
                    (grandchild, index) => <ChapterItemComponent
                        key={grandchild.key}
                        parent={child}
                        child={grandchild}
                        selectedKey={selectedKey}
                        handleSelectChild={handleSelectChild}
                        onCreate={onCreate}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        onChangeOrder={onChangeOrder}
                        allowWrite={allowWrite}
                        index={index}
                        indexMax={child.children.length - 1}
                        depth={depth+1}
                    />
                )}
            </div>
        }
    </>
}