import { Actions } from './action-type';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../models/root-state.model';
import { Constant } from '../constants';
import { Report, ReportChild } from '../models/report.model';
import { setError } from "./common.action";
import { request } from "../services/http.service";
import { getDateNearestHour } from '../services/date.service';
// import * as lodash from "lodash";
// import { Constant } from '../../constants';


export const getReportByMeeting = (
    id: string
) => {
    return async (dispatch: Dispatch<any>) => {
        // Otherwise
        try {
            const response = await request({
                method: 'GET',
                url: `/v1/meeting/report/${id}`,
            })

            // Cast
            const report: Report = response.data as Report;

            dispatch({
                type: Actions.MEETING_SET_REPORT,
                payload: report
            });

        } catch (error) {
            dispatch(setError(error));
        }
    }
}

export function initMeeting(report: Report) {
    return async (dispatch: Dispatch<AnyAction>) => {

        // Generate an id
        // const id = lodash.uniqueId();
        // const id = Date.now();
        const id = `${report.id}${getDateNearestHour().getTime()}`;

        try {
            await request({
                method: 'POST',
                url: '/v1/meeting',
                data: {
                    'meeting': id,
                    'report': report.id
                }
            })

            dispatch({
                type: Actions.MEETING_SET_ID,
                payload: id,
            });
        } catch (error) {
            dispatch(setError(error));
        }
    }
}

// Send that some text change
export function sendSelectedChildChange(child: ReportChild) {
    return async (dispatch: Dispatch<AnyAction>, getState: () => RootState) => {

        const store = getState();
        const meetingId = store.meeting.id;

        if(meetingId) {
            // Message
            const msg = {
                type: Constant.MEETING_SELECTED_CHILD_CHANGE,
                payload: {
                    key: child.key,
                    text: child.text,
                    state: child.state,
                    title: child.title,
                    parent: child.parentKey
                }
            }
    
            try {
                await request({
                    method: 'PATCH',
                    url: `/v1/meeting/${meetingId}`,
                    data: msg
                })
    
            } catch (error) {
                dispatch(setError(error));
            }
        } 

    }

}


/**
 * To close meeting
 */
export function closeMeeting() {

    return async (dispatch: Dispatch<any>, getState: () => RootState) => {

        const store = getState();
        const id = store.meeting.id;
        try {
            await request({
                method: 'DELETE',
                url: `/v1/meeting/${id}`,
            });

            dispatch(
                {
                    type: Actions.MEETING_END,
                    payload: ''
                }
            );

        } catch (error) {
            dispatch(setError(error));
        }
    };
}


