import * as React from "react";
// import { Dispatch } from 'redux';
// import { submit } from 'redux-form';
// import { useDispatch } from 'react-redux';

// import { ModalComponent } from "../ui/container/modal.component";
// import { PrimaryButtonComponent } from "../ui/button/button-primary.component";

// import { Report } from "../../models/report.model";

// import { addSnapshot } from "../../actions/snapshot.action";
// import { AlertComponent } from '../ui/container/alert.component';

// import { SnapshotComponentForm, IFormSnapshotData } from "./snapshot.form";
// import { Constant } from '../../constants';
// import { render } from "react-dom";
import { RowColumnComponent } from "../ui/container/row-colums.component";
// import { FieldInputComponent } from "../ui/field/field-input.component";
import { TextInputComponent } from "../ui/field/text-input.component";
import { CheckBoxInputComponent } from "../ui/field/checkbox-input.component";

export interface SnapshotInfo {
    label: string,
    onlyDifference: boolean
}

interface Props {
    snapshotInfo: SnapshotInfo,
    setSnapshotInfo: (snapshotInfos: SnapshotInfo) => void
}

export const SnapshotFormComponent: React.FunctionComponent<Props> = (props : Props) => {

    // Props
    const { snapshotInfo, setSnapshotInfo } = props;

    // Handle change
    const handleChangeLabel = (value: string) => {
        console.log(value);
        setSnapshotInfo({...snapshotInfo, label: value});
    }
    const handleChangeOnlyDifference = (checked: boolean) => {
        console.log(checked);
        setSnapshotInfo({...snapshotInfo, onlyDifference: checked});
    }

   

    return <>
        <RowColumnComponent>
            <TextInputComponent name="label" label="Snapshot Label" 
                value={ snapshotInfo.label }
                setValue= { handleChangeLabel }
                required={ true }
            />
            <div style={ { paddingTop: '30px'}} >
                <CheckBoxInputComponent name="onlyDifference" label="Only Differences"
                    checked= { snapshotInfo.onlyDifference }
                    setChecked={ handleChangeOnlyDifference }
                />
            </div>
        </RowColumnComponent>
    </>;

};