import * as React from "react";
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps } from "react-router-dom";

// import { Constant } from "../../constants";
import { DangerButtonComponent, LightButtonComponent } from '../ui/button';
import { Report, ReportChild, ReportContentHistory } from '../../models/report.model';
import { PreviewComponent } from '../common/preview.component';
import { ReportChaptersComponent, ReportChildHeaderComponent } from "../report";
import { SnapshotAddButtonComponent } from "../snapshot/snapshot-button-add.component";
import { ThreeSplitComponent } from "../ui/container/three-split.component";
import { SelectInputComponent } from "../ui/field/select-input.component";
import { findByKey } from "../../services/report.service";
import { IconBackComponent } from "../ui/icon";
import { canTakeSnapshot } from "../../services/access.service";
import { deleteSnapshot } from "../../actions/report-content-history/report-content-delete-snapshot.action";

interface Props {
    id: string,
    report: Report,
    username: string
}

export const SnapshotPage: React.FC<Props & RouteComponentProps> = ({ report, username, location }: Props & RouteComponentProps) => {
    const dispatch: Dispatch<any> = useDispatch();

    const snapshots = report?.content?.history;

    // Read query param
    const search = new URLSearchParams(location.search);
    const paramSnapshot = search.get('key');
    const paramChild = search.get('child');

    // State
    // --> Select snapshot
    const [selectedSnapshot, selectSnapshot] = React.useState<ReportContentHistory | undefined>(undefined);
    if (!selectedSnapshot && snapshots && snapshots.length > 0) {
        selectSnapshot(
            paramSnapshot ? snapshots.find(s => s.key === paramSnapshot) : snapshots[0]
        );
    }
    // --> Chapter ?
    const [selectedChild, selectChild] = React.useState<ReportChild | undefined>(undefined);
    if (!selectedChild && selectedSnapshot && selectedSnapshot.children && selectedSnapshot.children.length > 0) {
        selectChild(
            paramChild ? findByKey(paramChild, selectedSnapshot.children) : selectedSnapshot.children[0]
        );
    }

    /**
     * Change of snapshot
     */
    const handleSnapshotChange = React.useCallback((obj: ReportContentHistory) => {
        selectSnapshot(obj);
        selectChild(obj.children?.length > 0 ? obj.children[0] : undefined);
    }, [selectChild, selectSnapshot]);

    /**
     * 
     */
    const handleDeleteSnapshot = React.useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        if (report && selectedSnapshot && window.confirm('Are-you sure ?')) {
            dispatch(
                deleteSnapshot(
                    report,
                    selectedSnapshot
                )
            );
            selectSnapshot(undefined);
            selectChild(undefined);
        }
    }, [report, selectedSnapshot, dispatch]);

    return <>
        <div className="container" >
            <h1>
                <div className="float-right form-inline" >
                    { canTakeSnapshot(report) && <SnapshotAddButtonComponent data-test-id="btnSnapshotNew" report={report} username={username} /> }
                    <SelectSnapshotComponent snapshots={snapshots} selected={selectedSnapshot} onChange={handleSnapshotChange} />
                    { canTakeSnapshot(report) && <DangerButtonComponent  data-test-id="btnSnapshotDelete" onClick={handleDeleteSnapshot} className="ml-1" > Delete </DangerButtonComponent> }
                </div>
                <Link to={`/report/${report?.id}`} data-test-id="btnBack" >
                    <LightButtonComponent > <IconBackComponent /> </LightButtonComponent>
                </Link>
                <span data-test-id="snapshotTitle" >{selectedSnapshot ? selectedSnapshot.title : 'Snapshots'} </span>
            </h1>
            {selectedSnapshot && selectedChild &&
                <>
                    <ThreeSplitComponent leftTitle="Chapters" >
                        {
                            {
                                leftChild:
                                    <ReportChaptersComponent
                                        parent={selectedSnapshot}
                                        onCreate={() => { }}
                                        onUpdate={() => { }}
                                        onDelete={() => { }}
                                        selectedChild={selectedChild}
                                        onSelectChild={selectChild}
                                        onChangeOrder={() => { }}
                                    />
                                ,
                                mainContent: selectedChild && <>
                                    <ReportChildHeaderComponent child={selectedChild} />
                                    <div className="p-3" data-test-id="snapshotContent">
                                        <PreviewComponent text={selectedChild?.text} />
                                    </div>
                                </>
                            }
                        }
                    </ThreeSplitComponent>
                </>
            }
        </div>
    </>;
}


interface ISelectSnapshotProps {
    snapshots?: ReportContentHistory[]
    onChange?: (snapshot: ReportContentHistory) => void
    selected?: ReportContentHistory
}

// const EditorComponent:React.FunctionComponent<Props> = ({ text, setText }) => {
const SelectSnapshotComponent: React.FunctionComponent<ISelectSnapshotProps> = ({ snapshots, onChange, selected }) => {

    const handleSetSelectedValue = React.useCallback((value: string) => {
        const el = snapshots?.find(s => s.key === value);
        if (el && onChange) {
            onChange(el);
        }
    }, [snapshots, onChange]);

    const items = snapshots?.sort((a, b) => b.timespan - a.timespan)
        .map((s) => { return { code: s.key, label: s.title }; });


    return <>
        {
            snapshots
            && snapshots.length > 0
            && items
            && items.length > 0
            && <SelectInputComponent values={items} selected={selected ? selected.key : ''} setSelectedValue={handleSetSelectedValue} />
        }

    </>

}