import * as lodash from "lodash";
import { Constant } from '../constants';
import { Report, ReportChild, ReportContentHistory, ReportSearchResult } from "../models/report.model";

/**
 * 
 * Search a node in a list
 * 
 * @param key Key of search node
 * @param list The list where it searchs
 * @param current The current node
 * @param level Really ?
 */
export const findByKey = (key: string, list: ReportChild[], current?: ReportChild, level: number = 0): ReportChild | undefined => {
    // Current one is the good one ?
    if (current && current.key === key) {
        return current;
    }

    for (let index = 0; index < list.length; index++) {
        const elt = list[index];
        // Find
        const res = findByKey(key, elt.children, elt, level + 1);
        // Res ?
        if (res) {
            return res;
        }
    }

    // Nope again ...
    return;
}

/**
 * 
 * Clone a report for a snapshot
 * 
 * @param report Report
 * @param onlyDiff True : store only the difference for less data
 */
export const cloneForSnapshot = (
    report: Report,
    onlyDiff: boolean
): ReportChild[] => {

    // 
    let returnList: ReportChild[] = [];

    if (onlyDiff) {

        // More complex as we want only element which has change... but keep the tree ?

        // Search the snapshot
        const snapshot = report.content?.history.sort((a, b) => b.timespan - a.timespan)[0];

        // Get the children
        if (report && report.content && report.content.children && snapshot) {
            // generateDifferentList(report.content.children, returnList, snapshot);
            report.content.children.forEach(child => {
                const clone = cloneChildIfDifferent(child, snapshot);
                if (clone) {
                    returnList.push(clone);
                }
            });
        }

    } else {
        // Simple clone of the list
        if (report && report.content && report.content.children) {
            returnList = lodash.cloneDeep(report.content?.children);
        }
    }

    // 
    return returnList;
}

/**
 * Return a clone if different
 * @param child Original
 * @param snapshot Snapshot
 */
const cloneChildIfDifferent = (child: ReportChild, snapshot: ReportContentHistory): ReportChild | undefined => {

    // Init
    let clone: ReportChild | undefined = undefined;
    const clonedChildren: ReportChild[] = [];

    // Loop on children
    child.children.forEach(element => {
        const clone = cloneChildIfDifferent(element, snapshot);
        if (clone) {
            clonedChildren.push(clone);
        }
    });

    // Test 
    // If children : need to clone
    // If different : need to clone
    if ((clonedChildren.length > 0) || childIsDifferentFromSnapshot(child, snapshot)) {
        clone = {
            ...child,
            children: clonedChildren
        }
    }

    return clone;
}

/**
 * Return true if child is different
 * @param child Tested child
 * @param snapshot History
 */
const childIsDifferentFromSnapshot = (child: ReportChild, snapshot: ReportContentHistory): boolean => {

    // Find the history
    const historyChild = findByKey(child.key, snapshot.children);

    // Got one ?
    if (historyChild) {
        // Text change ?
        return historyChild.text !== child.text;
    }

    // No history ? Test on date
    // If child is more recent the snapshot : different !
    return child.timespan > snapshot.timespan;

}

/**
 * Filter content children based on the filter function
 */
export const filterContentChildren = (
    children: ReportChild[],
    filterCallback: (child: ReportChild) => boolean
): ReportChild[] => {

    // Init
    const filteredChildren: ReportChild[] = [];

    // Foreach
    children.forEach(child => {
        // Are my child filtered ?
        const temp = filterContentChildren(child.children, filterCallback);
        // So ?
        if (temp.length > 0 || filterCallback(child)) {
            filteredChildren.push(
                {
                    ...child,
                    children: temp
                }
            );
        }
    });
    return filteredChildren;
}

/**
 * 
 * @param report The report to search in
 * @param search What we are looking for
 * @param includeSnapshot Include search in history ?
 */
export const searchInReport = (
    report: Report,
    search: string,
    includeSnapshot: boolean = true
) : ReportSearchResult[] => {

    // Init
    const result: ReportSearchResult[] = [];

    // Search in content
    if(report.content && report.content.children && report.content.children.length > 0) {
        searchInReportContent(
            report.content?.children, // The list to look at
            search, // What we are looking for
            result, // The list be field,
            { 
                type: 'report',
                reportId: report.id,
                search: search,
                title: 'Current'
            } as ReportSearchResult // To be clone
        )
    }

    // Search in history / snapshot
    if(includeSnapshot && report.content) {
        report.content.history.forEach((s) => {
            searchInReportContent(
                s.children, // The list to look at
                search, // What we are looking for
                result, // The list be field,
                { 
                    type: 'snapshot',
                    reportId: report.id,
                    snapshotKey: s.key,
                    search: search,
                    title: s.title
                } as ReportSearchResult // To be clone
            )
        });
    }


    // Return
    return result;
} // /searchInReport

/**
 * 
 * @param children The list to look in
 * @param search WHat we are look for
 * @param result The result
 * @param template Base for an result item
 */
const searchInReportContent = (
    children: ReportChild[],
    search: string,
    result: ReportSearchResult[],
    template: ReportSearchResult
) : void => {

    // Loop
    children.forEach((child) => {
        // Child is a result ?
        if(child.text.includes(search)) {
            result.push({ 
                ...template,
                child: {...child, children: []}
            });
        }
        // Récursif
        searchInReportContent(
            child.children,
            search,
            result,
            template
        );
    });

} // /searchInReportContent

/**
 * 
 * @param code 
 */
export const getFilterCallBackForContentChildren = (
    code: string
): ((child: ReportChild) => boolean) => {

    switch (code) {
        case Constant.EXPORT_FILTER_RUNNING:
            return (child: ReportChild) => { return child.state === Constant.CHILD_STATE_RUNNING; }
        default:
            return (child: ReportChild) => { return true; }
    }
}



/**
 * Manage to update report on some property if needed
 * @param report Report to be updated
 * @returns true if updated
 */
export const updateReportContent = (report: Report) => {

    if (report.content && !report.content.version) {
        // Two things need to be done
        // Update the state and update parent key
        report.content.children.forEach((child, index) => {
            updateReportIfNeededFirstVersion(
                child, Constant.ROOT_PARENT_KEY, Constant.CHILD_STATE_RUNNING, index
            );
        });

        report.content.version = 1;

        return true;
    }


    return false;
}

/**
 * 
 * @param current The current node
 * @param parentKey The parent key to be set
 * @param state The state to be set
 */
const updateReportIfNeededFirstVersion = (current: ReportChild, parentKey: string, state: string, order: number) => {
    // Update child
    current.parentKey = parentKey;
    if (!current.state) { current.state = state as any; }
    current.order = order;

    // Loop
    current.children.forEach((child, index) => {
        updateReportIfNeededFirstVersion(
            child, current.key, Constant.CHILD_STATE_RUNNING, index
        );
    });

}