import * as React from "react";
import { Report } from '../../models/report.model';
import { Dispatch } from 'redux';
import { submit } from 'redux-form';
import { useDispatch } from 'react-redux';

import { ModalComponent } from "../ui/container/modal.component";
// import { PrimaryButtonComponent } from "../ui/button/button-primary.component";
// import { FieldInputComponent } from '../ui/field/field-input.component';

// import { ReportLight } from "../../models/report.model";

// import { postNewReport } from "../../store/actions/report.action";
import { AlertComponent } from '../ui/container/alert.component';

import { ReportComponentForm } from "./report.form";
import { putUpdateReport } from '../../actions/report.action';
import { Constant } from '../../constants';
import { IconComponent } from "../ui/icon";
import { LinkButtonComponent } from "../ui/button";

interface Props {
    report: Report
}

export const ReportEditButtonComponent: React.FunctionComponent<Props> = ({report}) => {

    const [showModal, setShowModal] = React.useState(false);
    const [error, setError] =  React.useState('');
    const dispatch: Dispatch<any> = useDispatch();

    const handleSave = () => {
        dispatch(submit(Constant.FORM_ADD_CONTENT))
    }

    const onSubmit = (form:Report) => {
        dispatch(
            putUpdateReport(
                form as Report,
                () => { setShowModal(false); },
                (error: string) => setError(error)
            )
        );
    }
    
    return <>
      { showModal 
            && <ModalComponent onClose={ () => { setShowModal(false) } } onSave={ handleSave } title="Edit report" saveTitle="Edit" > 
                    <AlertComponent message={error} />
                    <ReportComponentForm initialValues={report} onSubmit={onSubmit} />
                </ModalComponent> }
        <LinkButtonComponent onClick={() => setShowModal(! showModal)}  title="Parameters" > <IconComponent name="gear" /> </LinkButtonComponent>
    </>;
}
