import * as React from "react";
import { ButtonComponent } from './button.component';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement > {
   className?:string
}

export const DangerButtonComponent: React.FunctionComponent<Props> = props => {

  return (
      <>
           <ButtonComponent color={'danger'}  {...props } >
              { props.children} 
          </ButtonComponent>
      </>
  )
}

