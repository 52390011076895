import * as React from "react";

import { useDispatch, useSelector } from 'react-redux';
import { deleteResource, postResource } from "../../actions/resource.action";
// import { Constant } from "../../constants";

import { Report } from "../../models/report.model";
import { Resource } from "../../models/resource.model";
import { RootState } from "../../models/root-state.model";
import { generateMkImageLink } from "../../services/resource.service";
import { LinkButtonComponent, PrimaryButtonComponent } from '../ui/button';
import { RowColumnComponent } from '../ui/container/row-colums.component';
import { IconBigPlusComponent, IconClipBoardComponent, IconTrashComponent } from "../ui/icon";
import { PasteTextButtonComponent } from "../ui/tools/paste-text-button.component";


interface Props {
    report: Report
}

export const ReportResourcesComponent: React.FunctionComponent<Props> = (props: Props) => {

    // Explode Props
    const { report } = props;

    // State for the new element
    const [showNewInput, setShowNewInput] = React.useState<boolean>(false);

    // Get list from the store
    const list = useSelector((store: RootState) => {
        const temp = store.resource.resources[report.id];
        return temp ? temp : [];
    });

    return <>
        <RowColumnComponent>
            <div className="text-right" >
                <LinkButtonComponent onClick={() => { setShowNewInput(!showNewInput) }} > <IconBigPlusComponent /> </LinkButtonComponent>
            </div>
        </RowColumnComponent>
        {
            showNewInput
            && <ReportResourceAddComponent
                report={report}
                onFinish={() => setShowNewInput(false)}
            >
                Add
                        </ReportResourceAddComponent>

        }
        {
            list && list.map(resource => <ReportResourceItemComponent key={resource.id} report={report} resource={resource} />)
        }
    </>
}

interface ReportResourceAddProps {
    report: Report,
    onFinish: () => void,
    children: React.ReactNode
}

/**
 * Specific component to add a resource
 */
const ReportResourceAddComponent: React.FunctionComponent<ReportResourceAddProps> = React.memo((props) => {

    const dispatch: React.Dispatch<any> = useDispatch();
    const { report, children, onFinish } = props;

    const selectTypeRef = React.useRef<HTMLSelectElement | null>(null)
    const [selectedFile, setSelectedFile] = React.useState<any>(null);
    const [isSelected, setIsSelected] = React.useState(false);

    const changeHandler = (event: React.FormEvent<HTMLInputElement>) => {

        if (event && event.currentTarget && event.currentTarget.files) {
            setSelectedFile(event!.currentTarget!.files[0]);
            setIsSelected(true);
        }
    };

    // Handle a click on a new Var valid button
    const onSubmitClick = React.useCallback((e: React.SyntheticEvent) => {
        // 
        e.preventDefault();
        if (isSelected && onFinish && selectTypeRef.current && selectTypeRef.current.value) {
            // onSubmit(selectedFile);
            dispatch(
                postResource(
                    report,
                    selectTypeRef.current.value,
                    selectedFile
                )
            );
            onFinish();
        }

    }, [dispatch, isSelected, onFinish, report, selectedFile]);



    return <>
        <div className="container mt-3" style={{ backgroundColor: 'lightgray' }}>
            <div className="row pt-2">
                <div className="col form-group">
                    <select className="form-control" ref={selectTypeRef} >
                        <option value="image"> Image </option>
                        <option value="template" > Template </option>
                        <option value="other" > Other </option>
                    </select>
                </div>
            </div>
            <div className="row" >
                <div className="col form-group">
                    <input type="file" name="file" onChange={changeHandler} />
                </div>
            </div>
            <div className="row pb-2" >
                <div className="col text-right">
                    <PrimaryButtonComponent
                        id="new-ressource"
                        onClick={onSubmitClick}
                        disabled={!isSelected}
                    >
                        {children}
                    </PrimaryButtonComponent>
                </div>
            </div>

        </div>
    </>;

});

interface ReportResourceItemProps {
    report: Report,
    resource: Resource
}

const ReportResourceItemComponent: React.FunctionComponent<ReportResourceItemProps> = React.memo((props) => {

    const { report, resource } = props;

    const dispatch: React.Dispatch<any> = useDispatch();

    const handleDeleteClick = React.useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        dispatch(
            deleteResource(
                report,
                resource
            )
        );
    }, [dispatch, report, resource]);

    // Translate size to good unit
    const presentSize = React.useCallback((size: number) => {
        if(size > 1024 * 1024) return ` ${Math.round(size / 1024 / 1024)} Mo`;
        else if (size > 1024) return ` ${Math.round(size / 1024)} Ko`;
        return  ` ${size} o`;
    }, []);

    // 
    const presentLink = React.useCallback(() => {
        //return `![${resource.originalName}](${Constant.BASE_URL}${resource.url} "${resource.originalName}")`;
        return generateMkImageLink(resource);
    }, [resource]);

    return <>
        <RowColumnComponent className="mt-2" colSize={['', '4']}  key={`${resource.id}`} >
            <span>
                {resource.originalName} ( { presentSize(resource.size) } )
                </span>
            <div className="text-right" >
                {
                    resource.type === 'image' 
                        &&
                            <PasteTextButtonComponent text={ presentLink() }>
                                <LinkButtonComponent > <IconClipBoardComponent /> </LinkButtonComponent>
                            </PasteTextButtonComponent>
                }
                <LinkButtonComponent onClick={handleDeleteClick} > <IconTrashComponent /> </LinkButtonComponent>
            </div>
        </RowColumnComponent>
    </>
})