import * as React from "react";

import { PasteImageContainerComponent } from "../ui/container/";
import { Report, ReportChild } from "../../models/report.model";
import { EditorProps, EditorComponent  } from "../common/editor.component";
import { ReportChildHeaderComponent } from "../report";
import { useDispatch } from "react-redux";
import { postResource } from "../../actions/resource.action";

interface Props extends EditorProps {
    report: Report,
    selectedChild?: ReportChild
    changeChildState?: (state:string) => void
}

export const ReportEditorComponent: React.FunctionComponent<Props> = (props) => {

    const dispatch = useDispatch();

    // Get param
    const { text, setText, editor = 0, selectedChild, changeChildState, report } = props;

    const handleTextChange = React.useCallback((text: string) => {
        setText(text);
    }, [setText]);


    // Save the paste image
    const handlePasteSave = React.useCallback((filename: string, file: File, cb: () => void) => {
        dispatch(
            postResource(
                report,
                'image',
                file,
                filename,
                () => { cb() }
            )
        );
    }, [dispatch, report]);

     
    return <>
       
        { selectedChild && <ReportChildHeaderComponent child={selectedChild} readonly={false} changeChildState={changeChildState} /> }
        { selectedChild && 
                <PasteImageContainerComponent onSave={ handlePasteSave } id={ 'reportEditorPasteContainer' } 
                    style={{ height: 'calc(100% - 45px)', overflow: 'auto' }}
                >
                    <EditorComponent text={text} setText={handleTextChange} editor={editor} />
                </PasteImageContainerComponent>
        }
        { !selectedChild && <div className="h-50"> <h4 className="text-center pt-5"> Aucun chapitre sélectionné </h4></div>}

    </>;
}

